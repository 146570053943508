import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { LayoutWrapper, Loading } from '../../ui'
import { Footer, Header, Main } from '../'
import { AppState } from '../../store/GlobalState'

export const DashboardTemplate = ({ children }) => {
  const { isBooting } = useContext(AppState)

  return (
    <LayoutWrapper>
      <Header />
      <Main>{isBooting ? <Loading delay={300} /> : children}</Main>
      <Footer />
    </LayoutWrapper>
  )
}

DashboardTemplate.propTypes = {
  children: PropTypes.node.isRequired,
}

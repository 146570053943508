import styled from 'styled-components'
import PropTypes from 'prop-types'

import { themeValue, mediaQuery } from '../theme'
import { pixelsToEm } from '../utils'

export const Inset = styled.div`
  max-width: ${props => pixelsToEm(props.theme.siteWidth)};
  width: 100%;
  margin: 0 auto;
  padding-left: ${themeValue('space.base')};
  padding-right: ${themeValue('space.base')};

  ${mediaQuery.large`max-width: 90%;`};
  ${mediaQuery.xlarge`max-width: 80%;`};
`
Inset.displayName = 'Inset'

export const InsetInner = styled.div`
  width: ${props => (Number.isInteger(props.width) ? `${props.width}px` : props.width || '80%')};
  margin: 0 auto;
`
InsetInner.displayName = 'InsetInner'
InsetInner.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

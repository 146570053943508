import React, { Suspense, lazy } from 'react'

import { ErrorBoundary } from '../components/common/ErrorBoundary'
import { Helmet } from 'components/common/Helmet'
import { Loading } from 'ui'

export const Dashboard = () => {
  const GlanceGrid = lazy(() => import('components/glanceGrid/GlanceGrid'))
  const TitlePerformance = lazy(() => import('components/titlePerformance/TitlePerformance'))
  const TitleCirculation = lazy(() => import('components/titleCirculation/TitleCirculation'))

  return (
    <ErrorBoundary>
      <Helmet title="Dashboard" />

      <Suspense fallback={<Loading />}>
        <GlanceGrid />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <TitlePerformance />
      </Suspense>

      <Suspense fallback={<Loading />}>
        <TitleCirculation />
      </Suspense>
    </ErrorBoundary>
  )
}

import styled from 'styled-components'

import { mediaQuery, themeValue } from '../theme'

export const LayoutMain = styled.main`
  flex: 1;
  margin: ${themeValue('space.small')} 0;

  ${mediaQuery.medium`margin: ${themeValue('space.medium')} 0;`};
`
LayoutMain.displayName = 'LayoutMain'

import styled from 'styled-components'

export const LayoutWrapper = styled.article`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`
LayoutWrapper.displayName = 'LayoutWrapper'

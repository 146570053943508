import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import orderBy from 'lodash/orderBy'
import toUpper from 'lodash/toUpper'
import toNumber from 'lodash/toNumber'

import { themeValue } from '../theme'
import { TBody, TH } from '.'
import { Icon } from '../Icon'
import { BareButton } from '../Button'

export const SortableColumnHeader = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  svg {
    margin-left: ${themeValue('space.small')};
  }

  ${props =>
    props.center &&
    css`
      justify-content: center;
    `};
`
SortableColumnHeader.displayName = 'SortableColumnHeader'

export const SortableHeaderButton = styled(BareButton)`
  border-radius: ${themeValue('borderRadius')};
  padding: ${themeValue('space.tiny')} ${themeValue('space.small')};
  white-space: pre-line;
  line-height: inherit;

  &:hover,
  &:focus {
    background-color: ${themeValue('colors.backgroundDark')};
    box-shadow: 0 0 1px 1px ${themeValue('overlayColors.primary')};
  }

  ${props =>
    props.active &&
    css`
      background-color: ${themeValue('colors.backgroundDark')};
    `}

  @media print {
    white-space: pre-wrap;
    padding: 0;

    svg {
      display: none;
    }
  }
`
SortableHeaderButton.displayName = 'SortableHeaderButton'

const DEFAULT_SORT_ORDER = 'descending'

const SortableContext = React.createContext({
  collection: [],
  property: '',
  sortBy: () => {},
  sortDirection: DEFAULT_SORT_ORDER,
})

export const SortableTableHeader = ({ name, children, ...rest }) => (
  <SortableContext.Consumer>
    {({ sortBy, sortDirection, sortedProperty }) => {
      const active = sortedProperty === name
      return (
        <TH active={active}>
          <SortableColumnHeader {...rest}>
            <FormattedMessage
              id="sortableTable.sortButton.label"
              defaultMessage="Click to sort by {property}"
              values={{
                property: name,
              }}>
              {label => (
                <SortableHeaderButton title={label} aria-label={label} onClick={() => sortBy(name)} active={active}>
                  {children}
                  {!active && <Icon type="sortInactive" size={10} />}
                  {active && sortDirection === 'ascending' && <Icon type="sort" size={10} rotate={180} />}
                  {active && sortDirection === 'descending' && <Icon type="sort" size={10} />}
                </SortableHeaderButton>
              )}
            </FormattedMessage>
          </SortableColumnHeader>
        </TH>
      )
    }}
  </SortableContext.Consumer>
)

export const SortableTableBody = ({ children }) => (
  <TBody>
    <SortableContext.Consumer>{children}</SortableContext.Consumer>
  </TBody>
)

const sort = (collection, property, sortDirection) => {
  if (Number.isFinite(collection[0][property]) || /^\d+[.]?\d+$/.test(collection[0][property])) {
    const coerced = collection.map(row => ({ ...row, [property]: toNumber(row[property]) }))
    return orderBy(coerced, property, sortDirection)
  } else {
    return orderBy(collection, [item => toUpper(item[property])], sortDirection)
  }
}

export class SortableTable extends Component {
  constructor(props) {
    super(props)

    // When a property to sort by is clicked we update our state expectations base on
    // whether the user wants to change direction of the sort or change the property
    // they are sorting by.
    this.sortBy = newProp => {
      const { sortedProperty, sortDirection } = this.state

      if (sortedProperty === newProp) {
        this.setState({
          ...this.state,
          sortDirection: sortDirection === 'ascending' ? 'descending' : 'ascending',
        })
      } else {
        this.setState({
          sortedProperty: newProp,
          sortDirection: DEFAULT_SORT_ORDER,
        })
      }
    }

    this.state = {
      sortedProperty: this.props.defaultProperty,
      sortDirection: this.props.defaultSortDirection || DEFAULT_SORT_ORDER,
    }
  }

  render() {
    const { collection, children } = this.props
    const { sortedProperty, sortDirection } = this.state
    const direction = sortDirection === 'ascending' ? 'asc' : 'desc'

    const providerValue = {
      collection: sort(collection, sortedProperty, direction),
      sortedProperty,
      sortBy: this.sortBy,
      sortDirection,
    }

    return <SortableContext.Provider value={providerValue}>{children}</SortableContext.Provider>
  }
}

SortableTable.propTypes = {
  defaultProperty: PropTypes.string.isRequired,
  defaultSortDirection: PropTypes.string,
  collection: PropTypes.array.isRequired,
}

export const TableHeaderButton = ({ active, onClick, propertyName, sortDirection = 'DESC', center }) => {
  const property = propertyName[0].toLowerCase()
  return (
    <TH active={active} center={center}>
      <SortableColumnHeader center={center}>
        <FormattedMessage
          id="circulationsOverview.product.table.header.sortButton.label"
          defaultMessage="Click to sort by {property}"
          values={{
            property,
          }}>
          {label => (
            <SortableHeaderButton title={label} aria-label={label} onClick={onClick} active={active}>
              {propertyName}
              {!active && <Icon type="sortInactive" size={10} />}
              {active && sortDirection === 'ASC' && <Icon type="sort" size={10} rotate={180} />}
              {active && sortDirection === 'DESC' && <Icon type="sort" size={10} />}
            </SortableHeaderButton>
          )}
        </FormattedMessage>
      </SortableColumnHeader>
    </TH>
  )
}

export const getNewSortDirection = sortDirection => (sortDirection === 'ASC' ? 'DESC' : 'ASC')

import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'

import ProviderWrapper from 'utils/ProviderWrapper'
import App from './App'
import { AppStateProvider } from 'store/GlobalState'
import * as serviceWorker from './serviceWorker'
import { client } from 'store/api'

ReactDOM.render(
  <AppStateProvider>
    <ProviderWrapper>
      <ApolloProvider client={client}>
          <App />
      </ApolloProvider>
    </ProviderWrapper>
  </AppStateProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

import styled from 'styled-components'
import MediaQuery from 'react-responsive'

export const SmallBreakpoint = styled(MediaQuery).attrs(props => ({
  query: `(min-width: ${props.theme.sizes.small}px)`,
}))``

export const OnlySmallBreakpoint = styled(MediaQuery).attrs(props => ({
  query: `(max-width: ${props.theme.sizes.medium - 1}px)`,
}))``

export const MediumBreakpoint = styled(MediaQuery).attrs(props => ({
  query: `(min-width: ${props.theme.sizes.medium}px)`,
}))``

export const OnlyMediumBreakpoint = styled(MediaQuery).attrs(props => ({
  query: `(min-width: ${props.theme.sizes.medium}px) and (max-width: ${props.theme.sizes.large - 1}px)`,
}))``

export const BelowMediumLargeBreakpoint = styled(MediaQuery).attrs(props => ({
  query: `(max-width: ${props.theme.sizes.mediumLarge - 1}px)`,
}))``

export const MediumLargeBreakpoint = styled(MediaQuery).attrs(props => ({
  query: `(min-width: ${props.theme.sizes.mediumLarge}px)`,
}))``

export const LargeBreakpoint = styled(MediaQuery).attrs(props => ({
  query: `(min-width: ${props.theme.sizes.large}px)`,
}))``

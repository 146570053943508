import styled from 'styled-components/macro'

import { themeValue } from '../theme'

export const StatusIndicator = styled.div.attrs(props => ({
  'aria-hidden': 'true',
}))`
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: ${props => (props.color ? themeValue(props.color) : themeValue('colors.foreground'))};
  display: inline-block;

  & + & {
    margin-left: ${themeValue('space.tiny')};
  }
`
StatusIndicator.displayName = 'StatusIndicator'

import { css } from 'styled-components'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'

import { themeValue } from './theme'

/**
 * Make an element visually hidden, but
 * still readable by screen readers.
 * @return CSS to make elements visually hidden
 */
export const visuallyHidden = () => css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
`

/**
 * Base styling for form elements.
 * @return CSS properties for form elements
 */
export const formElementBase = () => css`
  ${fontSize(1)};
  appearance: none;
  font-family: inherit;
  padding: ${themeValue('space.tiny')} ${themeValue('space.small')};
  background: ${themeValue('colors.background')};
  color: ${themeValue('colors.foreground')};
  border-radius: ${themeValue('borderRadius')};
  height: auto;
  width: 100%;
  margin: 0;
  cursor: pointer;
  outline: none;

  &:focus,
  &:hover {
    outline: none;
    box-shadow: 0 0 1px ${themeValue('overlayColors.primary')};
  }

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${themeValue('colors.error.base')};
    `};

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${themeValue('colors.backgroundDark')};
    `};
`

/**
 * Base styling for notification messages
 * @return CSS properties for notification elements
 */
export const notificationBase = () => css`
  ${fontSize(1)};
  color: ${themeValue('colors.always.white')};
  display: block;
  font-weight: ${themeValue('fontWeights.bolder')};
  margin: ${themeValue('space.small')} 0;
  padding: ${space(0.4)} ${themeValue('space.small')};
  border-radius: ${themeValue('borderRadius')};
  border: 1px solid transparent;
  border-left-width: 10px;
  text-align: left;

  @media print {
    color: ${themeValue('colors.always.black')};
  }
`

/**
 * Set the color via provided theme path
 * @param {string} color - a path that passes through themeValue
 * @return CSS color property
 */
export const colorProp = (color) =>
  css`
    color: ${themeValue(color)};
  `

/**
 * Set the text alignment
 * @param {string} aligment - valid CSS text-alignment
 * @return CSS text-alignment property
 */
export const alignProp = (aligment) =>
  css`
    text-align: ${aligment};
  `

/**
 * Convert a pixel value to em.
 * @param {number} pixels - A pixel value to convert to em.
 * @return Em value
 */
export const pixelsToEm = (pixels) => (props) => `${pixels / props.theme.typography.baseFontSize}em`

/**
 * Get the raw number amount of a CSS property
 * @param {number|string} amount - The CSS value to get the raw number value from
 * @return The number amount
 */
export const getRawValue = (amount) => parseFloat(amount)

/**
 * Get an amount of spacing.
 * Useful for margin, padding, sizing (height, width).
 * @param {number} scaleValue - The scale factor value
 * @return CSS spacing amount in REM units
 */
export const space = (scaleValue) => (props) => {
  const { baseFontSize, baseLineHeight, rems } = props.theme.typography
  return `${(baseFontSize * baseLineHeight * scaleValue) / rems}rem`
}

/**
 *
 * @param {number} scaleValue - The font scale factor
 */
export const getFontSize = (scaleValue) => (props) => {
  const { baseFontSize, rems } = props.theme.typography
  return `${(baseFontSize * scaleValue) / rems}rem`
}

export const getLineHeight = () => (props) => props.theme.typography.baseLineHeight

/**
 * Get a predefined font size & line height.
 *
 * @param {number} level - An integer between 0 - 7
 *    Level 1 is regular paragraph text. Level 0 is smaller,
 *    above level 1 is larger. This parameter maps to a value in the modularScale array.
 *    0 => small text
 *    1 => <p> and <h4>
 *    2 => <h3>
 *    3 => <h2>
 *    4 => <h1>
 * @return {string} CSS. Font size and line height in REM units.
 */
export const fontSize = (level) => (props) => {
  if (isUndefined(level) || !Number.isInteger(level)) return null

  const scaleValue = props.theme.typography.fontScale[level]

  return css`
    font-size: ${getFontSize(scaleValue)};
  `
}

/**
 * Convert a hexadecimal color value to RGB or RGBA
 *
 * @param {string} hex - The hexadecimal color to convert to RGB
 * @param {string|number} [alpha] - A number between 0 - 1 defining the
 *    alpha channel. If not provided, RGB is generated instead of RGBA.
 */
export const hexToRGB = (hex, alpha) => {
  if (!hex || !isString(hex) || !hex.length === 7) {
    console.warn(`The provided hex color - ${hex} - was not in the valid format.`)
    return ''
  }

  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

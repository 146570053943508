import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { LayoutWrapper, Loading } from '../../ui'
import { AppState } from '../../store/GlobalState'

export const CustomizedTemplate = ({ children }) => {
  const { isBooting } = useContext(AppState)

  return <LayoutWrapper>{isBooting ? <Loading delay={300} /> : children}</LayoutWrapper>
}

CustomizedTemplate.propTypes = {
  children: PropTypes.node.isRequired,
}

import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

import { AppState } from '../../store/GlobalState'
import { CIRCULATION_METHOD_OPTIONS } from '../../store/constants'
import { themeValue, BareButton, Loading } from '../../ui'

//
// Model switcher UI
//
const ModelSwitcherItem = styled(BareButton)`
  color: ${themeValue('colors.always.white')};
  font-weight: ${themeValue('fontWeights.bold')};
  padding: 0 0.2em;
  border-bottom: 2px solid transparent;

  ${props =>
    props.active &&
    css`
      border-bottom-color: ${themeValue('colors.always.white')};
    `}

  &:hover,
  &:focus {
    background-color: ${themeValue('colors.primary.highlight')};
  }

  & + & {
    margin-left: ${themeValue('space.tiny')};
  }
`
ModelSwitcherItem.displayName = 'ModelSwitcherItem'

const ModelSwitcherMenu = styled.div`
  background-color: ${themeValue('colors.primary.base')};
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  padding: ${themeValue('space.tiny')};
  border-bottom-left-radius: ${themeValue('borderRadius')};
  border-bottom-right-radius: ${themeValue('borderRadius')};
  z-index: 10;
`
ModelSwitcherMenu.displayName = 'ModelSwitcherMenu'

export const ModelSwitcher = () => {
  const {
    method,
    changeMethod,
    settings: { vendor },
  } = useContext(AppState)

  const [isSwitching, setSwitching] = React.useState(false)

  const updateUIMethod = React.useCallback(
    async method => {
      if (!isSwitching) {
        setSwitching(true)
        await changeMethod(method)
        setSwitching(false)
      }
    },
    [isSwitching, setSwitching, changeMethod],
  )

  // if the vendor has only one product type available,
  // this model switcher is not available
  if (!vendor.productTypes || vendor.productTypes.length <= 1) {
    return null
  }

  return (
    <div
      style={{
        position: 'relative',
        margin: '0 auto',
      }}>
      <ModelSwitcherMenu>
        {Object.keys(CIRCULATION_METHOD_OPTIONS).map(option => (
          <ModelSwitcherItem
            disabled={isSwitching}
            key={CIRCULATION_METHOD_OPTIONS[option].id}
            active={method === CIRCULATION_METHOD_OPTIONS[option].id}
            onClick={() => updateUIMethod(CIRCULATION_METHOD_OPTIONS[option].id)}>
            {CIRCULATION_METHOD_OPTIONS[option].label}
          </ModelSwitcherItem>
        ))}
      </ModelSwitcherMenu>

      <div style={{ position: 'absolute', right: '-3rem', top: '0.5rem' }}>
        {isSwitching && <Loading width={24} height={24} />}
      </div>
    </div>
  )
}

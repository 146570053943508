import { useState, useEffect, useLayoutEffect, useRef } from 'react'

const throttle = (throttledFunction, wait, context) => {
  let timeoutId = null
  let callbackArgs = []

  const later = () => {
    throttledFunction.apply(context, callbackArgs)
    timeoutId = null
  }

  return (...args) => {
    if (!timeoutId) {
      callbackArgs = args
      timeoutId = setTimeout(later, wait)
    }
  }
}

export const useBreakpoints = breakpoints => {
  if (!breakpoints || !breakpoints.length) {
    throw new Error(
      'An array of breakpoints matching { width: number, value: any } must be provided to the useBreakpoints hook.',
    )
  }

  const mountedRef = useRef(false)
  const [currentBreakpoint, setBreakpoint] = useState(breakpoints[0].value)

  const updateBreakpoints = throttle(() => {
    const match = breakpoints.find(point => window.innerWidth > point.width)
    if (mountedRef.current && match && currentBreakpoint !== match.value) setBreakpoint(match.value)
  }, 250)

  useEffect(() => {
    mountedRef.current = true
    window.addEventListener('resize', updateBreakpoints)
    return () => {
      mountedRef.current = false
      window.removeEventListener('resize', updateBreakpoints)
    }
  })

  useLayoutEffect(() => {
    updateBreakpoints()
  })

  return currentBreakpoint
}

import React, { Suspense, lazy } from 'react'

import { ErrorBoundary } from '../components/common/ErrorBoundary'
import { Helmet } from 'components/common/Helmet'
import { Loading } from 'ui'

export const Reports = () => {
  const ReportList = lazy(() => import('components/reportList/ReportList'))

  return (
    <ErrorBoundary>
      <Helmet title="Reports" />

      <Suspense fallback={<Loading />}>
        <ReportList />
      </Suspense>
    </ErrorBoundary>
  )
}

import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { AppState } from '../../store/GlobalState'
import { Icon, IconButton, MobileMenuContainer, MobileMenuSection, Navigation, P, Strong } from 'ui'
import { Portal } from './Portal'
import SelectVendorModal from './SelectVendorModal'

export const MobileMenu = () => {
  return (
    <Portal closeOnEsc>
      <Portal.Toggle>
        {({ openPortal }) => (
          <FormattedMessage id="mobileMenu.opener.label" defaultMessage="Open menu">
            {label => (
              <IconButton onClick={openPortal} aria-label={label}>
                <Icon type="menu" />
              </IconButton>
            )}
          </FormattedMessage>
        )}
      </Portal.Toggle>

      <Portal.Content>
        <MobileMenuContent />
      </Portal.Content>
    </Portal>
  )
}

const MobileMenuContent = ({ closePortal: closeMenu }) => {
  const {
    settings: { user, vendor },
    token,
    logout,
  } = useContext(AppState)

  return (
    <MobileMenuContainer>
      <FormattedMessage id="mobileMenu.closer.label" defaultMessage="Close menu">
        {label => (
          <IconButton onClick={closeMenu} aria-label={label}>
            <Icon type="close" />
          </IconButton>
        )}
      </FormattedMessage>

      <MobileMenuSection>
        {token && (
          <P align="center">
            <Strong>{user.email}</Strong>
            <br />
            <Strong>{vendor.name}</Strong>
          </P>
        )}
      </MobileMenuSection>

      <MobileMenuSection>
        <Navigation>
          <Link to="/dashboard/circulations" onClick={closeMenu}>
            <Strong>
              <FormattedMessage id="mobileMenu.circulations" defaultMessage="Circulations and Sales" />
            </Strong>
          </Link>

          <Link to="/dashboard/inventory" onClick={closeMenu}>
            <Strong>
              <FormattedMessage id="mobileMenu.inventory" defaultMessage="Inventory" />
            </Strong>
          </Link>

          <Link to="/dashboard/documents" onClick={closeMenu}>
            <Strong>
              <FormattedMessage id="mobileMenu.documents" defaultMessage="Documents" />
            </Strong>
          </Link>

          <Link to="/dashboard/users" onClick={closeMenu}>
            <Strong>
              <FormattedMessage id="mobileMenu.users" defaultMessage="Users" />
            </Strong>
          </Link>

          <Link to="/dashboard/reports" onClick={closeMenu}>
            <Strong>
              <FormattedMessage id="mobileMenu.reports" defaultMessage="Reports" />
            </Strong>
          </Link>
        </Navigation>
      </MobileMenuSection>

      <MobileMenuSection>
        <Navigation>
          {user.isAdmin && (
            <Portal closeOnEsc>
              <Portal.Toggle>
                {({ openPortal }) => (
                  <Link
                    to={''}
                    onClick={() => {
                      closeMenu()
                      openPortal()
                    }}>
                    <Strong>
                      <FormattedMessage id="mobileMenu.switchVendors" defaultMessage="Switch vendors" />
                    </Strong>
                  </Link>
                )}
              </Portal.Toggle>

              <Portal.Content>
                <SelectVendorModal />
              </Portal.Content>
            </Portal>
          )}

          <Link to="/dashboard/help" onClick={closeMenu}>
            <Strong>
              <FormattedMessage id="mobileMenu.help" defaultMessage="Help" />
            </Strong>
          </Link>

          <Link
            to="/"
            onClick={() => {
              closeMenu()
              logout()
            }}>
            <Strong>
              <FormattedMessage id="mobileMenu.logout" defaultMessage="Logout" />
            </Strong>
          </Link>
        </Navigation>
      </MobileMenuSection>
    </MobileMenuContainer>
  )
}

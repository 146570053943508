import React, { useState, useEffect, Fragment, useRef } from 'react'
import styled from 'styled-components'

import { themeValue } from '../theme'

const LoadingIcon = styled.svg`
  color: ${props => themeValue(props.color)};
`
LoadingIcon.displayName = 'LoadingIcon'

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
`
LoadingContainer.displayName = 'LoadingContainer'

export const Loading = ({ color, delay, height, width }) => {
  let timeoutId = useRef(null)

  const [isDelayed, setDelay] = useState(true)

  useEffect(() => {
    if (!delay) {
      setDelay(false)
    } else {
      setDelay(true)
      timeoutId.current = setTimeout(() => {
        setDelay(false)
      }, delay)
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    }
  }, [delay])

  return (
    <Fragment>
      {isDelayed && null}
      {!isDelayed && (
        <LoadingContainer>
          <LoadingIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width={width}
            height={height}
            color={color}
            style={{
              fill: 'currentColor',
            }}>
            <path transform="translate(2)" d="M0 12 V20 H4 V12z">
              <animate
                attributeName="d"
                values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
                dur="1.2s"
                repeatCount="indefinite"
                begin="0"
                keyTimes="0;.2;.5;1"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
                calcMode="spline"
              />
            </path>
            <path transform="translate(8)" d="M0 12 V20 H4 V12z">
              <animate
                attributeName="d"
                values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
                dur="1.2s"
                repeatCount="indefinite"
                begin="0.2"
                keyTimes="0;.2;.5;1"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
                calcMode="spline"
              />
            </path>
            <path transform="translate(14)" d="M0 12 V20 H4 V12z">
              <animate
                attributeName="d"
                values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
                dur="1.2s"
                repeatCount="indefinite"
                begin="0.4"
                keyTimes="0;.2;.5;1"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
                calcMode="spline"
              />
            </path>
            <path transform="translate(20)" d="M0 12 V20 H4 V12z">
              <animate
                attributeName="d"
                values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
                dur="1.2s"
                repeatCount="indefinite"
                begin="0.6"
                keyTimes="0;.2;.5;1"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
                calcMode="spline"
              />
            </path>
            <path transform="translate(26)" d="M0 12 V20 H4 V12z">
              <animate
                attributeName="d"
                values="M0 12 V20 H4 V12z; M0 4 V28 H4 V4z; M0 12 V20 H4 V12z; M0 12 V20 H4 V12z"
                dur="1.2s"
                repeatCount="indefinite"
                begin="0.8"
                keyTimes="0;.2;.5;1"
                keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.8 0.4 0.8"
                calcMode="spline"
              />
            </path>
          </LoadingIcon>
        </LoadingContainer>
      )}
    </Fragment>
  )
}

Loading.defaultProps = {
  width: 64,
  height: 64,
  color: 'colors.primary.base',
}

import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'

import { ButtonGroup, Modal, P, PrimaryButton } from 'ui'
import { Portal } from 'components/common/Portal'

const MaintenanceModal = ({ closePortal }) => {
  const closeModal = useCallback(() => {
    closePortal()
    return window.location.reload(true)
  }, [closePortal])

  return (
    <Modal
      closeModal={closeModal}
      title={<FormattedMessage id="modals.maintenance.title" defaultMessage="Maintenance in progress" />}>
      <P>
        <FormattedMessage
          id="modals.maintenance.content"
          defaultMessage="We have released a new version of this web site. You'll need to refresh this page now to continue."
        />
      </P>

      <ButtonGroup align="flex-end">
        <PrimaryButton onClick={closeModal}>
          <FormattedMessage id="modals.maintenance.button" defaultMessage="Reload" />
        </PrimaryButton>
      </ButtonGroup>
    </Modal>
  )
}

const MaintenanceNotice = () => {
  return (
    <Portal closeOnEsc={true} defaultOpen={true}>
      <Portal.Content>
        <MaintenanceModal />
      </Portal.Content>
    </Portal>
  )
}

export default MaintenanceNotice

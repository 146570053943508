import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { themeValue } from '../theme'
import { space, fontSize } from '../utils'
import { H3, Span } from '../Typography'

export const Teaser = styled(Link)`
  margin: 0 auto;
  background-color: ${themeValue('colors.background')};
  box-shadow: ${themeValue('boxShadow')};
  height: 100%;
  min-height: inherit;
  display: block;
  text-decoration: none;
  color: ${themeValue('colors.foreground')};
  border-radius: ${themeValue('borderRadius')};
  overflow: hidden;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: ${themeValue('colors.primary.highlight')};
  }

  @media print {
    width: 200px;
    margin: 5px;
    display: inline-block;
    page-break-inside: avoid;
    page-break-after: auto;
    border: 1px solid ${themeValue('colors.secondary.pale')};
  }
`
Teaser.displayName = 'Teaser'

export const TeaserName = styled(H3)`
  ${fontSize(1)};
  text-transform: none;
  flex: 1;
  white-space: pre-line;
  hyphens: auto;
`
TeaserName.displayName = 'TeaserName'

export const TeaserMeta = styled(Span)`
  display: block;
`
TeaserMeta.displayName = 'TeaserMeta'

export const TeaserContent = styled.div`
  margin: 0;
  padding: ${themeValue('space.small')};
  min-height: ${space(7)};
  display: flex;
  flex-direction: column;

  @media print {
    min-height: initial;
  }
`
TeaserContent.displayName = 'TeaserContent'

const TeaserImageBg = styled.img`
  position: absolute;
  display: block;
  filter: blur(15px);
  transform: scale(1.2);
  opacity: 0.4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
TeaserImageBg.displayName = 'TeaserImageBg'

const TeaserImageFg = styled.img`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
`
TeaserImageFg.displayName = 'TeaserImageFg'

const TeaserImageWrapper = styled.div`
  position: relative;
  height: ${space(9)};
  overflow: hidden;
`
TeaserImageWrapper.displayName = 'TeaserImageWrapper'

const ComposedTeaserImage = ({ src, alt, className }) => (
  <TeaserImageWrapper>
    <TeaserImageBg src={src} alt={alt} className={className} width={270} />
    <TeaserImageFg src={src} alt={alt} className={className} width={270} />
  </TeaserImageWrapper>
)

export const TeaserImage = styled(ComposedTeaserImage)``
TeaserImage.displayName = 'TeaserImage'

export const TeaserBanner = styled.p`
  padding: ${themeValue('space.tiny')} ${themeValue('space.small')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > * + * {
    margin-left: ${themeValue('space.tiny')};
  }
`
TeaserBanner.displayName = 'TeaserBanner'

import React, { Suspense, lazy } from 'react'

import { ErrorBoundary } from '../components/common/ErrorBoundary'
import { Helmet } from 'components/common/Helmet'
import { Loading } from 'ui'

export const Documents = () => {
  const DocumentList = lazy(() => import('components/documentList/DocumentList'))

  return (
    <ErrorBoundary>
      <Helmet title="Documents" />

      <Suspense fallback={<Loading />}>
        <DocumentList />
      </Suspense>
    </ErrorBoundary>
  )
}

import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { themeValue } from '../theme'

export const Background = styled.div`
  background-color: ${props => themeValue(props.color)};
  ${props =>
    props.shadow &&
    css`
      box-shadow: ${themeValue('boxShadow')};
    `};

  ${props =>
    props.padding &&
    css`
      padding: ${themeValue(props.padding)};
    `};

  ${props =>
    props.fullsize &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
    `}
`

Background.displayName = 'Background'

Background.propTypes = {
  color: PropTypes.string,
  shadow: PropTypes.bool,
}

Background.defaultProps = {
  color: 'colors.background',
  shadow: true,
}

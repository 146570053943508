import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import MasqueradeNotice from './components/common/MasqueradeNotice'
import { ErrorBoundary } from './components/common/ErrorBoundary'
import {
  Circulations,
  Dashboard,
  DashboardPaths,
  Documents,
  Help,
  Homepage,
  Inventory,
  ProductDetail,
  Reports,
  Users,
} from 'pages'

const App = () => (
  <ErrorBoundary>
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route element={<DashboardPaths />} path="dashboard">
            <Route element={<Circulations />} path="circulations" />
            <Route element={<Dashboard />} index={true} />
            <Route element={<Documents />} path="documents" />
            <Route element={<Help />} path="help" />
            <Route element={<Inventory />} path="inventory" />
            <Route element={<Reports />} path="reports" />
            <Route element={<ProductDetail />} path="products/:titleName/:productId" />
            <Route element={<Users />} path="users" />
          </Route>
          <Route element={<Homepage />} path="/" />
          <Route element={<Homepage />} path="*" />
        </Routes>
      </BrowserRouter>
      <MasqueradeNotice />
    </React.Fragment>
  </ErrorBoundary>
)

export default App

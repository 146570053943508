import React, { Suspense, lazy } from 'react'

import { ErrorBoundary } from '../components/common/ErrorBoundary'
import { Helmet } from 'components/common/Helmet'
import { Loading } from 'ui'

export const Help = () => {
  const HelpList = lazy(() => import('components/helpList/HelpList'))
  return (
    <ErrorBoundary>
      <Helmet title="Help" />
      <Suspense fallback={<Loading />}>
        <HelpList />
      </Suspense>
    </ErrorBoundary>
  )
}

import { useReducer, useMemo, useRef, useEffect } from 'react'

import Storage from '../../store/Storage'

// storage interface
const storage = new Storage()

/**
 * Create a reducer that persists its own state
 *
 * @param defaultState {object} - the default state for this reducer
 * @param storageKey {string} - the key used to identify this state in the storage
 * @param ttl {?number} - ms that this state should be cached. if not
 *    provided, then the ttl is ignored (i.e. it's cached forever)
 * @param overrideState {object} - if provided, this object will merge over the
 *    default state and the stored state combination
 *
 * @returns {*[]} - [state, setState]
 */
export function useStoredReducer(defaultState, storageKey, ttl = null, overrideState = null) {
  if (typeof storageKey !== 'string') {
    throw new Error('A valid storage key must be passed to useStoredReducer')
  }

  if (typeof defaultState !== 'object' || Array.isArray(defaultState)) {
    throw new Error('A valid default state (object) must be passed to useStoredReducer')
  }

  // track if this component is mounted
  const isMounted = useRef(false)

  // create the initial state. it is either
  // stored in local storage, or we use the
  // defaultState
  const initialState = useMemo(() => {
    const storedState = storage.load(storageKey)
    const init = null === defaultState ? storedState : Object.assign({}, defaultState, storedState, overrideState)
    return init
  }, [defaultState, storageKey, overrideState])

  const [state, setState] = useReducer((state, newState) => {
    let nextState = null

    if (newState === null) {
      // if the next state was null, then remove the stored state
      storage.remove(storageKey)
    } else {
      // create the next state based on current and provided
      nextState = { ...state, ...newState }

      // save the next state in storage
      storage.save(storageKey, nextState, ttl)
    }

    // use the next state in the app
    return nextState
  }, initialState)

  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  const safeSetState = (...args) => isMounted.current && setState(...args)

  return [state, safeSetState]
}

import React, { Suspense, lazy } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { ErrorBoundary } from '../components/common/ErrorBoundary'
import { Header, Footer } from '../components'
import { themeValue, mediaQuery, Background, LayoutWrapper, Loading } from 'ui'

export const ProductDetail = () => {
  const Product = lazy(() => import('components/product/Product'))
  let { productId } = useParams()
  return (
    <Background color="colors.backgroundDark" shadow={false}>
      <LayoutWrapper>
        <Header showSwitcher={false} />
        <ProductContent>
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Product productId={productId} />
            </Suspense>
          </ErrorBoundary>
        </ProductContent>
        <Footer />
      </LayoutWrapper>
    </Background>
  )
}

const ProductContent = styled.main`
  flex: 1;
  margin: 0 0 ${themeValue('space.small')} 0;

  ${mediaQuery.medium`margin: 0 0 ${themeValue('space.medium')} 0;`};
`
ProductContent.displayName = 'ProductContent'

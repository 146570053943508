import React from 'react'
import PropTypes from 'prop-types'

import { Inset, LayoutMain } from 'ui'

export const Main = ({ children }) => (
  <LayoutMain>
    <Inset>{children}</Inset>
  </LayoutMain>
)

Main.propTypes = {
  children: PropTypes.any,
}

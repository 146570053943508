import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Formik } from 'formik'
import { Query } from '@apollo/client/react/components'

import { GET_VENDORS_LIST, CHANGE_VENDOR } from '../../store/queries'
import { AppState } from '../../store/GlobalState'
import { ButtonGroup, Form, FormGroup, Modal, NotificationError, PrimaryButton, SecondaryButton } from '../../ui'
import SelectVendorList from './SelectVendorList'

const SelectVendorModal = ({ closePortal }) => {
  const { completeLogin } = useContext(AppState)

  return (
    <Query query={GET_VENDORS_LIST}>
      {({ client, data, error, loading }) => {
        return (
          <Modal
            allowContentOverflow={true}
            closeModal={closePortal}
            title={<FormattedMessage id="modals.selectVendor.title" defaultMessage="Select a vendor" />}>
            <Formik
              initialValues={{ vendorSelection: '' }}
              onSubmit={async ({ vendorSelection }, { setStatus, setSubmitting }) => {
                try {
                  // grab the vendor based on
                  // the selection in the dropdown menu
                  const selectedVendor = data.vendors.filter(v => v.id === vendorSelection)[0]

                  // get a new auth token for the new vendor
                  const results = await client.mutate({
                    mutation: CHANGE_VENDOR,
                    variables: {
                      vendorId: selectedVendor.id,
                    },
                  })

                  // update global state with new vendor
                  await completeLogin(results.data.changeVendor)

                  // close this portal
                  closePortal()

                  // reset apollo storage so new data is
                  // automatically fetched
                  client.resetStore()
                } catch (ex) {
                  setStatus({
                    failed: (
                      <FormattedMessage
                        id="modals.selectVendor.error"
                        defaultMessage="Unable to change vendors now. Please try this again later."
                      />
                    ),
                  })
                } finally {
                  setSubmitting(false)
                }
              }}>
              {({ handleBlur, handleSubmit, isSubmitting, setFieldValue, status }) => {
                const errorMessage = error ? error.message : status ? status.failed : null
                return (
                  <Form onSubmit={handleSubmit}>
                    {errorMessage && (
                      <FormGroup>
                        <NotificationError>{errorMessage}</NotificationError>
                      </FormGroup>
                    )}

                    <SelectVendorList isDisabled={isSubmitting} setFieldValue={setFieldValue} handleBlur={handleBlur} />

                    <ButtonGroup align="flex-end">
                      <PrimaryButton type="submit" disabled={isSubmitting || loading}>
                        <FormattedMessage id="modals.selectVendor.button.submit" defaultMessage="Select" />
                      </PrimaryButton>
                      <SecondaryButton type="button" onClick={closePortal}>
                        <FormattedMessage id="modals.selectVendor.button.cancel" defaultMessage="Cancel" />
                      </SecondaryButton>
                    </ButtonGroup>
                  </Form>
                )
              }}
            </Formik>
          </Modal>
        )
      }}
    </Query>
  )
}

export default SelectVendorModal

import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Query } from '@apollo/client/react/components'
import Select from 'react-select'
import { ThemeContext } from 'styled-components'

import { GET_VENDORS_LIST } from '../../store/queries'
import { FormGroup, FormLabel, Loading } from '../../ui'

const getCustomSelectStyles = vendorTheme => ({
  menuList: provided => ({
    ...provided,
    maxHeight: 150,
  }),
  option: provided => ({
    ...provided,
    color: vendorTheme.colors.foreground,
    backgroundColor: vendorTheme.colors.backgroundDark,
    '&:hover, &:active, &:focus': {
      color: vendorTheme.colors.background,
      backgroundColor: vendorTheme.colors.foregroundDark,
    },
  }),
})

const SelectVendorList = ({ hasError, highlight, setFieldValue, handleBlur, isDisabled }) => {
  const vendorTheme = useContext(ThemeContext)
  const customSelectStyles = getCustomSelectStyles(vendorTheme)

  return (
    <Query query={GET_VENDORS_LIST}>
      {({ loading, data }) => {
        if (loading || !data || !data.vendors || !data.vendors.length) return <Loading width={28} height={28} />

        return (
          <FormGroup highlight={highlight}>
            <FormLabel id="selectVendorMenu">
              <FormattedMessage id="selectVendor.form.select.label" defaultMessage="Chose a vendor from the list" />
            </FormLabel>
            <Select
              isDisabled={isDisabled}
              theme={theme => ({
                ...theme,
                spacing: {
                  ...theme.spacing,
                  controlHeight: 20,
                },
                borderRadius: vendorTheme.borderRadius,
                colors: {
                  ...theme.colors,
                  text: vendorTheme.colors.foreground,
                  primary25: vendorTheme.colors.secondary.pale,
                  primary: vendorTheme.colors.secondary.highlight,
                  neutral0: vendorTheme.colors.background,
                  neutral20: hasError ? vendorTheme.colors.error.base : vendorTheme.colors.secondary.pale,
                  neutral80: vendorTheme.colors.foreground,
                },
              })}
              styles={customSelectStyles}
              id="vendorSelection"
              onChange={option => setFieldValue('vendorSelection', option.value)}
              onBlur={handleBlur}
              options={data.vendors.map(v => ({
                value: v.id,
                label: v.name,
              }))}
              aria-labelledby="selectVendorMenu"
              hasError={hasError}
            />
          </FormGroup>
        )
      }}
    </Query>
  )
}

export default SelectVendorList

import React, { Fragment, useState, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { ThemeProvider } from 'styled-components'
import { locale } from 'utils/internationalize'

import * as appThemes from '../ui/theme'
import { GlobalStyle } from '../ui/GlobalStyle'
import useDarkColorScheme from '../components/hooks/useDarkColorScheme'

import messages from '../locale-data/messages'

//
// This ProviderWrapper exists so that intl and theme providers
// can be shared with the app and with the docz docs.
//

const intlLocale = 'en'

const ProviderWrapper = ({ children }) => {
  const prefersDarkColorScheme = useDarkColorScheme()
  const [currentTheme, setCurrentTheme] = useState(appThemes.theme)

  useEffect(() => {
    setCurrentTheme(prefersDarkColorScheme ? appThemes.themeDark : appThemes.theme)

    return undefined
  }, [prefersDarkColorScheme])

  return (
    <IntlProvider messages={messages[intlLocale]} locale={intlLocale} defaultLocale="en">
      <ThemeProvider theme={currentTheme}>
        <Fragment>
          {children}
          <GlobalStyle />
        </Fragment>
      </ThemeProvider>
    </IntlProvider>
  )
}

export default ProviderWrapper

import React from 'react'
import styled, { css } from 'styled-components'

import { mediaQuery, themeValue } from '../theme'
import { space } from '../utils'
import { Background } from '../Background'

const StyledTable = styled.table`
  width: auto;
  min-width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
  ${mediaQuery.medium`width: 100%;`};
  ${mediaQuery.large`
    ${props =>
      props.fixed &&
      css`
        table-layout: fixed;
      `}
  `};
`
StyledTable.displayName = 'StyledTable'

const TableWrapper = styled(Background)`
  max-width: 100%;
  padding: ${themeValue('space.tiny')} ${space(0.75)} ${space(0.75)};
  overflow-x: scroll;
  ${props =>
    props.condensed &&
    css`
      padding: 0;
    `}

  @media print {
    overflow: visible;
    padding: 0;
  }
`
TableWrapper.displayName = 'TableWrapper'

const ComposedTable = ({ children, ...rest }) => (
  <TableWrapper {...rest}>
    <StyledTable>{children}</StyledTable>
  </TableWrapper>
)

export const Table = styled(ComposedTable)``
Table.displayName = 'Table'

export const TH = styled.th`
  text-align: left;
  text-transform: uppercase;
  cursor: default;
  font-size: inherit;
  font-weight: ${themeValue('fontWeights.bold')};
  line-height: 1;
  padding: ${themeValue('space.tiny')};
  vertical-align: middle;

  ${props =>
    props.center &&
    css`
      text-align: center;
    `};
`
TH.displayName = 'TH'

export const TR = styled.tr`
  &:hover {
    background-color: ${themeValue('colors.backgroundDark')};
  }

  /* If the tr has an onClick attached, add the cursor to
     signify to the user that an action can occur on click. */
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`
TR.displayName = 'TR'

export const THead = styled.thead`
  ${TR} {
    border-bottom: 1px solid ${themeValue('colors.secondary.highlight')};

    &:hover {
      background-color: transparent;
    }
  }
`
THead.displayName = 'THead'

export const TBody = styled.tbody`
  ${props =>
    props.striped &&
    css`
      ${TR} {
        border-bottom: 1px solid ${themeValue('colors.secondary.pale')};

        &:last-child {
          border-bottom: none;
        }
      }
    `};
`
TBody.displayName = 'TBody'
TBody.defaultProps = {
  striped: true,
}

export const TD = styled.td`
  padding: ${themeValue('space.small')};
  white-space: initial;
  max-width: 20rem;

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${props =>
    props.center &&
    css`
      text-align: center;

      button {
        margin: 0 auto;
      }
    `}
  
  ${props =>
    props.right &&
    css`
      text-align: right;
    `}
`
TD.displayName = 'TD'

export const TFoot = styled.tfoot`
  ${TR}:first-of-type {
    border-top: 1px solid ${themeValue('colors.secondary.highlight')};

    &:hover {
      background-color: transparent;
    }
  }

  ${TD} {
    padding: ${themeValue('space.small')};
  }
`
TFoot.displayName = 'TFoot'

export const TCaption = styled.caption`
  caption-side: ${props => props.align || 'bottom'};
  text-align: center;
  padding: ${themeValue('space.tiny')};
`
TCaption.displayName = 'TCaption'

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Background, BareButton, Form, H1, HeroImage, Inset, InsetInner, mediaQuery, P, Strong, themeValue } from 'ui'
import { Portal } from '../common/Portal'
import { ForgotPassword } from '../common/ForgotPassword'
import { LoginForm } from './LoginForm'
import Logo from '../common/hoopla-login-logo.png'
import HeaderBackgroundImage from './seattlelibrary.jpg'

export const HomepageHeader = () => {
  return (
    <Fragment>
      <HeroImage image={HeaderBackgroundImage}>
        <HomepageHeaderWrapper>
          <HomepageHeaderTop>
            <img src={Logo} alt="" />
          </HomepageHeaderTop>

          <HomepageHeaderContent>
            <Background color="colors.primary.base">
              <P color="colors.always.white" align="center">
                <Strong transform="uppercase">
                  <FormattedMessage id="homepage.header.editorial.header" defaultMessage="View the latest" />
                </Strong>
                <br />
                <Strong>
                  <FormattedMessage
                    id="homepage.header.editorial.content"
                    defaultMessage="Click {link} to view the latest hoopla editorial calendar."
                    values={{
                      link: (
                        <a
                          href="https://s3.amazonaws.com/com.hoopladigital.web/vendor/Editorial_Calendar.pdf"
                          target="_blank"
                          rel="noreferrer noopener">
                          <FormattedMessage id="homepage.header.editorial.here" defaultMessage="here" />
                        </a>
                      ),
                    }}
                  />
                </Strong>
              </P>
            </Background>

            <Background>
              <H1 fontSize={2} align="center">
                <FormattedMessage id="homepage.header.title" defaultMessage="Welcome to hoopla" />
              </H1>

              <LoginForm />

              <P align="center" color="colors.primary.base">
                <Portal closeOnEsc>
                  <Portal.Toggle>
                    {({ openPortal }) => (
                      <BareButton onClick={openPortal}>
                        <FormattedMessage
                          id="homepage.header.forgotPassword"
                          defaultMessage="Forgot your password? Click here."
                        />
                      </BareButton>
                    )}
                  </Portal.Toggle>

                  <Portal.Content>
                    <ForgotPassword />
                  </Portal.Content>
                </Portal>
              </P>

              <P align="center">
                <FormattedMessage
                  id="homepage.header.assistanceCall"
                  defaultMessage="For assistance please call 1-800-875-2785."
                />
              </P>
            </Background>
          </HomepageHeaderContent>

          <HomepageHeaderFooter>
            <P fontSize={4} color="colors.always.white" align="center">
              <FormattedMessage
                id="homepage.header.message"
                defaultMessage="Learn why more digital media providers see hoopla as a leading choice."
              />
            </P>
          </HomepageHeaderFooter>
        </HomepageHeaderWrapper>
      </HeroImage>
    </Fragment>
  )
}

const HomepageHeaderWrapper = styled.header`
  position: absolute;
  top: ${themeValue('space.medium')};
  left: 0;
  bottom: ${themeValue('space.medium')};
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
HomepageHeaderWrapper.displayName = 'HomepageHeaderWrapper'

const HomepageHeaderTop = styled(Inset)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    height: ${themeValue('space.large')};
    width: auto;
    display: block;
    max-width: 100%;
  }
`
HomepageHeaderTop.displayName = 'HomepageHeaderTop'

const HomepageHeaderContent = styled(InsetInner)`
  min-width: 300px;
  max-width: 80%;
  width: auto;

  ${mediaQuery.medium`max-width: 300px;`};

  ${Background}:first-child {
    padding: ${themeValue('space.small')} ${themeValue('space.medium')};
    margin-bottom: ${themeValue('space.base')};
    border-radius: ${themeValue('borderRadius')};
    a {
      color: ${themeValue('colors.always.white')};
    }
  }

  ${Background}:last-child {
    border-radius: ${themeValue('borderRadius')};
    padding: ${themeValue('space.base')};
  }

  ${Form} {
    margin: ${themeValue('space.base')} 0;
  }

  ${P} {
    margin: 0;

    ${BareButton} {
      display: inline;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }
`
HomepageHeaderContent.displayName = 'HomepageHeaderContent'

const HomepageHeaderFooter = styled(InsetInner)`
  text-align: center;
`
HomepageHeaderFooter.displayName = 'HomepageHeaderFooter'

import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation Login($username: String!, $password: String!, $vendorId: ID) {
    login(username: $username, password: $password, vendorId: $vendorId)
  }
`

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      status
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $token: String!, $newPassword: String!) {
    resetPassword(email: $email, token: $token, newPassword: $newPassword) {
      status
    }
  }
`

export const NEW_USER_FROM_ADMIN = gql`
  mutation NewUserFromAdmin($newEmail: String!, $token: String!, $newPassword: String!) {
    newUserFromAdmin(newEmail: $newEmail, token: $token, newPassword: $newPassword)
  }
`

export const CHANGE_VENDOR = gql`
  mutation ChangeVendor($vendorId: ID!) {
    changeVendor(vendorId: $vendorId)
  }
`

export const CONTACT_INFO = gql`
  mutation ContactInfo($email: String!, $name: String!, $company: String, $title: String) {
    contactInfo(email: $email, name: $name, company: $company, title: $title) {
      status
    }
  }
`

export const GET_KINDS = gql`
  query Kinds($language: String) {
    kinds(language: $language) {
      id
      enabled
      name
      singular
      plural
    }
  }
`

export const GET_VENDORS_LIST = gql`
  query Vendors {
    vendors {
      id
      name
    }
  }
`

export const GET_VENDOR = gql`
  query Vendor($id: ID!) {
    vendor(id: $id) {
      id
      name
      enabled
      sapId
      currency
      hideInstantRevenue
      hideFlexCircs
      hideFlexLibraryNames
      hideInstantLibraryNames
      hideHolds
      hideFlexRevenue
      productTypes
      hideMargin
    }
  }
`

export const GET_TITLE_PERFORMANCE = gql`
  query TitlePerformance($reportType: TitlePerformanceReportType!, $startDate: Date, $endDate: Date) {
    titlePerformance(startDate: $startDate, endDate: $endDate, reportType: $reportType) {
      data {
        title {
          contentId
          title
          artKey
          titleId
        }
        product {
          id
          purchaseModel
        }
        circulations
        revenue
        earnings
        displayDate
      }
    }
  }
`

export const GET_GLANCE = gql`
  query Glance($startDate: Date, $endDate: Date) {
    activeTitlesGlance {
      data {
        instant
        flex
        all
      }
    }
    currentEarningsGlance(startDate: $startDate, endDate: $endDate) {
      data {
        instant
        flex
        all
      }
    }
    borrowingActivityGlance(startDate: $startDate, endDate: $endDate) {
      data {
        circulations
        patrons
        titles
      }
    }
    circulationsGlance(startDate: $startDate, endDate: $endDate) {
      data {
        instant
        flex
        all
      }
    }
    catalogDiscoveryGlance(startDate: $startDate, endDate: $endDate) {
      data {
        kind {
          id
          plural
        }
        circulationMethods {
          all {
            count
          }
          flex {
            count
          }
          instant {
            count
          }
        }
      }
    }
    summaryChart(startDate: $startDate, endDate: $endDate) {
      data {
        date
        revenue
        circulations
        earnings
      }
    }
  }
`

export const GET_CIRCULATIONS_OVERVIEW_FORMATS = gql`
  query CirculationsOverviewFormats {
    circulationsOverviewFormats {
      kind {
        id
        plural
      }
    }
  }
`

export const GET_CIRCULATIONS_OVERVIEW_BY_DATE = gql`
  query CirculationsOverviewByDate(
    $startDate: Date
    $endDate: Date
    $kindId: String
    $publisher: String
    $sortColumn: SortColumn
    $sortDirection: SortDirection
  ) {
    circulationsOverviewByDate(
      startDate: $startDate
      endDate: $endDate
      kindId: $kindId
      publisherId: $publisher
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      data {
        startDate
        endDate
        circulations
        revenue
        earnings
        circulationMethods
        quantitySold
      }
    }
  }
`

export const GET_CIRCULATIONS_OVERVIEW_BY_TITLE = gql`
  query CirculationsOverviewByTitle(
    $startDate: Date
    $endDate: Date
    $kindId: String
    $publisher: String
    $sortColumn: SortColumn
    $sortDirection: SortDirection
  ) {
    circulationsOverviewByTitle(
      startDate: $startDate
      endDate: $endDate
      kindId: $kindId
      publisherId: $publisher
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      data {
        startDate
        endDate
        circulations
        revenue
        earnings
        circulationMethods
        quantitySold
        title {
          contentId
          title
          groupTitle
        }
      }
    }
  }
`

export const GET_DAILY_CIRCULATIONS_OVERVIEW = gql`
  query DailyCirculationsOverview($startDate: Date, $endDate: Date, $kindId: String, $publisher: String) {
    dailyCirculationsOverview(startDate: $startDate, endDate: $endDate, kindId: $kindId, publisherId: $publisher) {
      data {
        title {
          contentId
          title
          groupTitle
          isbn
          upc
          kindId
        }
        product {
          id
          purchaseModel
        }
        kind {
          id
          singular
          plural
        }
        country {
          abbreviation
        }
        quantitySold
        circulations
        earnings
        revenue
        purchaseMethod
      }
    }
  }
`

export const GET_SALES_DETAIL_BY_PRODUCT = gql`
  query SalesDetailByProduct(
    $startDate: Date
    $endDate: Date
    $sortColumn: SalesDetailByProductSort
    $sortDirection: SortDirection
    $page: Int
  ) {
    salesDetailByProduct(
      startDate: $startDate
      endDate: $endDate
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      page: $page
    ) {
      pagination {
        currentPage
        nextPage
        pageCount
        previousPage
        startCount
        totalCount
        totalPages
      }
      data {
        country
        isbn
        kindName
        licenseDescription
        localDLP
        localDLPCurrency
        paymentDLP
        paymentDLPCurrency
        productId
        promotionalDiscountPercent
        purchaseModel
        quantitySold
        earnings
        earningsCurrency
        sales
        salesCurrency
        salesDate
        title
        titleId
        vendorDLP
        vendorDLPCurrency
        vendorMargin
      }
    }
  }
`

export const GET_CIRCULATION_DETAIL = gql`
  query CirculationDetail(
    $group: CirculationDetailGroup!
    $sortColumn: SortColumn
    $sortDirection: SortDirection
    $startDate: Date
    $endDate: Date
  ) {
    circulationDetail(
      group: $group
      sortColumn: $sortColumn
      sortDirection: $sortDirection
      startDate: $startDate
      endDate: $endDate
    ) {
      data {
        library {
          id
          name
        }
        title {
          contentId
          title
        }
        circulations
        patrons
        earnings
        revenue
        quantitySold
      }
    }
  }
`

export const GET_USERS = gql`
  query VendorUsers($vendorId: ID) {
    vendorUsers(vendorId: $vendorId) {
      id
      name
      email
      enabled
      vendorId
    }
  }
`
export const GET_DOCUMENTS_REPORT = gql`
  query DocumentsReport {
    documentsReport {
      id
      docType
      description
      created
      path
      docNumber
    }
  }
`

export const GET_DOCUMENT = gql`
  query GetDocumentsReport($path: String) {
    getDocuments(path: $path) {
      document
    }
  }
`

export const GET_PRODUCT_INVENTORY = gql`
  query GetProductInventory(
    $page: Int
    $searchValue: String
    $sortColumn: InventoryProductSort
    $sortDirection: SortDirection
  ) {
    productInventory(page: $page, searchValue: $searchValue, sortColumn: $sortColumn, sortDirection: $sortDirection) {
      pagination {
        currentPage
        nextPage
        pageCount
        previousPage
        startCount
        totalCount
        totalPages
      }
      data {
        countries
        displayDate
        groupTitle
        id
        kindName
        licenseType
        licensedMonths
        maximumCirculations
        publisherId
        publisherName
        purchaseMethod
        releaseDate
        title
        titleId
        vendorContentId
      }
    }
  }
`

export const GET_PRODUCT_DETAIL = gql`
  query GetProductDetail($productId: ID!) {
    productDetail(productId: $productId) {
      found
      artKey
      contentId
      imprint {
        id
        name
      }
      isbn
      upc
      releaseDate
      displayDate
      genres {
        id
        name
      }
      tags {
        id
        name
      }
      upc
      title
      titleId
      groupTitle
      kindId
      kind {
        singular
      }
      pages
      seconds
      series {
        id
        name
      }
      subTitle
      abridged
      fiction
      children
      publisher {
        id
        name
      }
      purchaseMethod
      primaryArtist {
        id
        name
        artKey
      }
      language {
        label
        name
      }
      year
      synopsis
      artists {
        id
        name
        role
      }
      productAvailability {
        licenseType
        purchaseMethod
        forSale
        country
      }
      vendorContentId
    }
  }
`

export const GET_PRODUCT_DETAIL_CIRCULATIONS = gql`
  query GetProductDetailCirculations($productId: ID!) {
    productDetailCirculations(productId: $productId) {
      startDate
      circulations
      revenue
      earnings
    }
  }
`

export const GET_PRODUCT_CIRCULATIONS = gql`
  query GetTitleCirculations($startDate: Date, $endDate: Date, $sortColumn: SortColumn, $sortDirection: SortDirection) {
    productCirculation(
      startDate: $startDate
      endDate: $endDate
      sortColumn: $sortColumn
      sortDirection: $sortDirection
    ) {
      data {
        circulationMethod
        title {
          contentId
          title
          groupTitle
          kindId
        }
        kind {
          id
          plural
          singular
        }
        product {
          id
          purchaseModel
        }
        circulationMethods
        circulations
        revenue
        earnings
      }
    }
  }
`

export const GET_VENDOR_PUBLISHERS = gql`
  query VendorPublishers($vendorId: ID) {
    vendorPublishers(vendorId: $vendorId) {
      id
      name
    }
  }
`

export const GET_REPORT_LIST = gql`
  query ReportList {
    listReports {
      configurationId
      createdDate
      startDate
      endDate
      format
      frequency
      dateRange
      subject
      recipients
      reportType
      adminView
    }
  }
`

export const CREATE_REPORT = gql`
  mutation CreateReport(
    $reportType: ReportType
    $recipients: [String]
    $subject: String
    $format: String
    $frequency: ReportFrequency
    $dateRange: String
  ) {
    createReport(
      reportType: $reportType
      recipients: $recipients
      subject: $subject
      format: $format
      frequency: $frequency
      dateRange: $dateRange
    ) {
      configurationId
      createdDate
      startDate
      endDate
      format
      frequency
      dateRange
      subject
      recipients
      reportType
    }
  }
`

export const SEND_REPORT_NOW = gql`
  mutation CreateReport(
    $reportType: ReportType
    $recipients: [String]
    $subject: String
    $format: String
    $dateRange: String
    $startDate: Date
    $endDate: Date
  ) {
    sendReportNow(
      reportType: $reportType
      recipients: $recipients
      subject: $subject
      format: $format
      dateRange: $dateRange
      startDate: $startDate
      endDate: $endDate
    ) {
      status
      success
    }
  }
`

export const DELETE_REPORT = gql`
  mutation DeleteReport($configurationId: String!) {
    deleteReport(configurationId: $configurationId) {
      status
      success
    }
  }
`

import styled from 'styled-components'
import { themeValue } from '../theme'

export const Tag = styled.span`
  background-color: ${themeValue('colors.secondary.interaction')};
  border: 1px solid ${themeValue('colors.secondary.intense')};
  color: ${themeValue('colors.always.white')};
  padding: 0 ${themeValue('space.small')};
  border-radius: ${themeValue('borderRadius')};
`
Tag.displayName = 'Tag'

export const TagList = styled.span`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  ${Tag} {
    margin: ${themeValue('space.tiny')} ${themeValue('space.small')} 0 0;
  }
`
TagList.displayName = 'TagList'

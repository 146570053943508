import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { Mutation } from '@apollo/client/react/components'

import { LOGIN } from '../../store/queries'
import { makeValidator } from '../../utils/form'
import { AppState } from '../../store/GlobalState'
import {
  ButtonGroup,
  Form,
  FormError,
  FormRequiredNotice,
  NotificationError,
  NotificationInfo,
  FormGroup,
  FormLabel,
  Input,
  PrimaryButton,
} from '../../ui'
import SelectVendorList from '../common/SelectVendorList'
import { RequiredMessage } from '../common/Messages'

const constraints = {
  username: {
    presence: true,
  },
  password: {
    presence: true,
  },
}

export const LoginForm = () => {
  const { completeLogin } = useContext(AppState)
  const navigate = useNavigate()

  const [showVendorList, setShowVendorList] = useState(false)

  return (
    <Mutation mutation={LOGIN}>
      {(login, { error }) => {
        // create a place for error messages from GraphQL
        let errorMessage = false

        if (error) {
          if (
            error.message.toLowerCase().indexOf('username not found') >= 0 ||
            error.message.toLowerCase().indexOf('incorrect password') >= 0
          ) {
            errorMessage = (
              <FormattedMessage
                id="forms.login.error.usernamePassword"
                defaultMessage="Username or password incorrect"
              />
            )
          } else if (error.message.toLowerCase().indexOf('disabled') >= 0) {
            errorMessage = (
              <FormattedMessage id="forms.login.error.disabled" defaultMessage="This account is no longer enabled." />
            )
          }
        }

        return (
          <Formik
            initialValues={{ username: '', password: '', vendorSelection: '' }}
            onSubmit={async ({ username, password, vendorSelection }, { setSubmitting }) => {
              // reset the error message
              errorMessage = false
              setSubmitting(true)

              try {
                // build variables needed to login
                const loginVariables = {
                  username,
                  password,
                }

                if (vendorSelection.length) {
                  // the second time an admin user tries to login,
                  // `vendorSelection` should have a value
                  loginVariables.vendorId = vendorSelection
                }

                // try to login
                const user = await login({
                  variables: loginVariables,
                })

                // finish login steps
                await completeLogin(user.data.login)

                // go to the dashboard
                navigate('/dashboard')
              } catch (ex) {
                if (ex.message.toLowerCase().indexOf('vendor id missing') >= 0) {
                  setShowVendorList(true)
                }
              } finally {
                setSubmitting(false)
              }
            }}
            validate={makeValidator(constraints)}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values,
              isSubmitting,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                {errorMessage && (
                  <FormGroup>
                    <NotificationError>{errorMessage}</NotificationError>
                  </FormGroup>
                )}

                {showVendorList && (
                  <FormGroup>
                    <NotificationInfo>
                      <FormattedMessage
                        id="forms.login.choseVendor"
                        defaultMessage="Once the list of vendors loads, please select one before continuing to log in."
                      />
                    </NotificationInfo>
                  </FormGroup>
                )}

                <FormGroup>
                  <FormLabel htmlFor="username">
                    <FormattedMessage id="forms.login.username.label" defaultMessage="Username" />
                    {!touched.username && values.username === '' && (
                      <FormRequiredNotice>
                        <RequiredMessage />
                      </FormRequiredNotice>
                    )}
                  </FormLabel>
                  <FormattedMessage id="forms.login.username.placeholder" defaultMessage="Enter your username">
                    {placeholder => (
                      <Input
                        id="username"
                        hasError={touched.username && errors.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={placeholder}
                        value={values.username}
                      />
                    )}
                  </FormattedMessage>
                  {touched.username && errors.username && <FormError>{errors.username}</FormError>}
                </FormGroup>

                <FormGroup>
                  <FormLabel htmlFor="password">
                    <FormattedMessage id="forms.login.password.label" defaultMessage="Password" />
                    {!touched.password && values.password === '' && (
                      <FormRequiredNotice>
                        <RequiredMessage />
                      </FormRequiredNotice>
                    )}
                  </FormLabel>
                  <FormattedMessage id="forms.login.password.placeholder" defaultMessage="Enter your password">
                    {placeholder => (
                      <Input
                        id="password"
                        type="password"
                        hasError={touched.password && errors.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={placeholder}
                        value={values.password}
                      />
                    )}
                  </FormattedMessage>
                  {touched.password && errors.password && <FormError>{errors.password}</FormError>}
                </FormGroup>

                {showVendorList && (
                  <SelectVendorList highlight={true} setFieldValue={setFieldValue} handleBlur={handleBlur} />
                )}

                <ButtonGroup align="center">
                  <PrimaryButton disabled={!dirty || isSubmitting || Object.keys(errors).length} type="submit">
                    <FormattedMessage id="forms.login.button" defaultMessage="Sign in" />
                  </PrimaryButton>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        )
      }}
    </Mutation>
  )
}

import React, { useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import { NEW_USER_FROM_ADMIN } from '../../store/queries'
import { makeValidator } from 'utils/form'

import {
  ButtonGroup,
  Form,
  FormError,
  FormGroup,
  FormRequiredNotice,
  NotificationError,
  NotificationSuccess,
  FormLabel,
  Input,
  Modal,
  PrimaryButton,
  SecondaryButton,
} from 'ui'
import { RequiredMessage } from './Messages'
import { AppState } from '../../store/GlobalState'

const constraints = {
  loginEmail: {
    presence: true,
    email: true,
  },
  newPassword: {
    presence: true,
  },
  newPasswordConfirm: {
    presence: true,
    equality: {
      attribute: 'newPassword',
      message: <FormattedMessage id="validator.resetPassword" defaultMessage="Passwords are not matching." />,
      comparator: (v1, v2) => {
        return v1 === v2
      },
    },
  },
}

export const NewUserFromAdmin = ({ closePortal, token }) => {
  const { completeLogin } = useContext(AppState)
  const [newUserFromAdmin, { data, loading }] = useMutation(NEW_USER_FROM_ADMIN)
  const navigate = useNavigate()
  useEffect(() => {
    if (!loading && data && data.newUserFromAdmin) {
      completeLogin(data.newUserFromAdmin).then(() => {
        // go to the dashboard
        navigate('/dashboard')
      })
    }

    return undefined
  }, [data, loading, completeLogin])

  return (
    <Modal
      closeModal={closePortal}
      title={<FormattedMessage id="modals.resetPassword.title" defaultMessage="Update your password" />}>
      <Formik
        initialValues={{
          loginEmail: '',
          newPassword: '',
          newPasswordConfirm: '',
        }}
        onSubmit={async ({ loginEmail, newPassword, newPasswordConfirm }, { setStatus, setSubmitting }) => {
          if (newPassword !== newPasswordConfirm) {
            return setStatus({
              failed: (
                <FormattedMessage
                  id="modals.resetPassword.mismatch"
                  defaultMessage="Your password and confirmation must be matching."
                />
              ),
            })
          }

          try {
            await newUserFromAdmin({ variables: { newEmail: loginEmail, token, newPassword } })

            setStatus({
              success: (
                <FormattedMessage
                  id="modals.resetPassword.success"
                  defaultMessage="Your password has now been reset."
                />
              ),
            })
          } catch (ex) {
            setStatus({ failed: ex.message.replace(/GraphQL error: /, '') })
          } finally {
            setSubmitting(false)
          }
        }}
        validate={makeValidator(constraints)}>
        {({ dirty, errors, handleBlur, handleChange, handleSubmit, isSubmitting, status, touched, values }) => (
          <Form onSubmit={handleSubmit}>
            {status && status.failed && (
              <FormGroup>
                <NotificationError>{status.failed}</NotificationError>
              </FormGroup>
            )}

            {status && status.success && (
              <FormGroup>
                <NotificationSuccess>{status.success}</NotificationSuccess>
              </FormGroup>
            )}

            <FormGroup>
              <FormLabel htmlFor="loginEmail">
                <FormattedMessage id="modals.resetPassword.form.loginEmail.label" defaultMessage="Email" />
                {!touched.loginEmail && values.loginEmail === '' && (
                  <FormRequiredNotice>
                    <RequiredMessage />
                  </FormRequiredNotice>
                )}
              </FormLabel>
              <FormattedMessage
                id="modals.resetPassword.form.loginEmail.placeholder"
                defaultMessage="Enter your address">
                {placeholder => (
                  <Input
                    id="loginEmail"
                    type="email"
                    required
                    placeholder={placeholder}
                    hasError={touched.loginEmail && errors.loginEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.loginEmail}
                  />
                )}
              </FormattedMessage>
              {touched.loginEmail && errors.loginEmail && <FormError>{errors.loginEmail}</FormError>}
            </FormGroup>

            <FormGroup>
              <FormLabel htmlFor="newPassword">
                <FormattedMessage id="modals.resetPassword.form.password.label" defaultMessage="Password" />
                {!touched.newPassword && values.newPassword === '' && (
                  <FormRequiredNotice>
                    <RequiredMessage />
                  </FormRequiredNotice>
                )}
              </FormLabel>
              <FormattedMessage
                id="modals.resetPassword.form.password.placeholder"
                defaultMessage="Enter your new password">
                {placeholder => (
                  <Input
                    id="newPassword"
                    type="password"
                    required
                    placeholder={placeholder}
                    hasError={touched.newPassword && errors.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                  />
                )}
              </FormattedMessage>
              {touched.newPassword && errors.newPassword && <FormError>{errors.newPassword}</FormError>}
            </FormGroup>

            <FormGroup>
              <FormLabel htmlFor="newPasswordConfirm">
                <FormattedMessage
                  id="modals.resetPassword.form.passwordConfirm.label"
                  defaultMessage="Confirm your new password"
                />
                {!touched.newPasswordConfirm && values.newPasswordConfirm === '' && (
                  <FormRequiredNotice>
                    <RequiredMessage />
                  </FormRequiredNotice>
                )}
              </FormLabel>
              <FormattedMessage
                id="modals.resetPassword.form.passwordConfirm.placeholder"
                defaultMessage="Enter your new password a second time">
                {placeholder => (
                  <Input
                    id="newPasswordConfirm"
                    type="password"
                    required
                    placeholder={placeholder}
                    hasError={touched.newPasswordConfirm && errors.newPasswordConfirm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPasswordConfirm}
                  />
                )}
              </FormattedMessage>
              {touched.newPasswordConfirm && errors.newPasswordConfirm && (
                <FormError>{errors.newPasswordConfirm}</FormError>
              )}
            </FormGroup>

            <ButtonGroup align="flex-end">
              <PrimaryButton disabled={!dirty || isSubmitting || Object.keys(errors).length} type="submit">
                <FormattedMessage id="modals.resetPassword.form.button.submit" defaultMessage="Update" />
              </PrimaryButton>
              <SecondaryButton type="button" onClick={closePortal}>
                <FormattedMessage id="modals.resetPassword.form.button.cancel" defaultMessage="Cancel" />
              </SecondaryButton>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

NewUserFromAdmin.propTypes = {
  token: PropTypes.string.isRequired,
}

import validate from 'validate.js/validate'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import mapValues from 'lodash/mapValues'

validate.validators.presence.options = {
  message: <FormattedMessage id="validator.required" defaultMessage="Required" />,
}

validate.validators.email.options = {
  message: <FormattedMessage id="validator.email" defaultMessage="Enter a valid email address" />,
}

const options = {
  fullMessages: false,
  format: 'grouped',
}

const sanitizeValue = (val) => {
  if (typeof val !== 'string') return val
  return val.trim() || null
}

export const makeValidator = (constraints) => {
  return (formValues) => {
    const sanitizedValues = mapValues(formValues, sanitizeValue)
    const errors = validate(sanitizedValues, constraints, options)
    return errors ? mapValues(errors, (e) => e[0]) : {}
  }
}

export const validateEmail = (email) => {
  const errors = validate(
    { email: email },
    {
      email: {
        presence: true,
        email: true,
      },
    },
    options,
  )
  return errors ? mapValues(errors, (e) => e[0]) : {}
}

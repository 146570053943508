import React from 'react'

import {
  AllMessage,
  AllTimeMessage,
  AtAGlanceReportMessage,
  AudiobookMessage,
  ComicMessage,
  CustomRangeMessage,
  DailyFrequencyMessage,
  EbookMessage,
  FlexMessage,
  InstantMessage,
  InventoryReportMessage,
  LastMonthTimeMessage,
  LastWeekTimeMessage,
  MonthlyFrequencyMessage,
  MovieMessage,
  MusicMessage,
  OnDemandFrequencyMessage,
  QuarterTimeMessage,
  SalesDetailsReportMessage,
  SalesOverviewReportMessage,
  TelevisionMessage,
  TitleCirculationReportMessage,
  TitlePerformanceReportMessage,
  TodayTimeMessage,
  WeeklyFrequencyMessage,
  YesterdayTimeMessage,
} from '../components/common/Messages'

export const CIRCULATION_METHOD_OPTIONS = {
  flex: {
    id: 'flex',
    label: <FlexMessage />,
  },
  instant: {
    id: 'instant',
    label: <InstantMessage />,
  },
  all: {
    id: 'all',
    label: <AllMessage />,
  },
}

export const PURCHASE_MODEL_OPTIONS = {
  est: {
    id: 'est',
    label: <FlexMessage />,
  },
  ppu: {
    id: 'ppu',
    label: <InstantMessage />,
  },
  all: {
    id: 'all',
    label: <AllMessage />,
  },
}

export const KIND_SINGULAR = {
  AUDIOBOOK: <AudiobookMessage />,
  COMIC: <ComicMessage />,
  EBOOK: <EbookMessage />,
  MOVIE: <MovieMessage />,
  MUSIC: <MusicMessage />,
  TELEVISION: <TelevisionMessage />,
}

export const FREQUENCY = {
  daily: {
    id: 'DAILY',
    label: <DailyFrequencyMessage />,
  },
  weekly: {
    id: 'WEEKLY',
    label: <WeeklyFrequencyMessage />,
  },
  monthly: {
    id: 'MONTHLY',
    label: <MonthlyFrequencyMessage />,
  },
  quarter_hour: {
    id: 'QUARTER_HOUR',
    label: <OnDemandFrequencyMessage />,
  },
}

export const TIME_FRAME = {
  all: 'ALL',
  month: 'LAST_MONTH',
  quarter: 'LAST_THREE_MONTHS',
  today: 'TODAY',
  custom: 'CUSTOM',
  week: 'LAST_WEEK',
  yesterday: 'PREVIOUS_DAY',
}

export const TIME_FRAME_MESSAGE = {
  ALL: <AllTimeMessage />,
  LAST_MONTH: <LastMonthTimeMessage />,
  LAST_THREE_MONTHS: <QuarterTimeMessage />,
  TODAY: <TodayTimeMessage />,
  CUSTOM: <CustomRangeMessage />,
  LAST_WEEK: <LastWeekTimeMessage />,
  PREVIOUS_DAY: <YesterdayTimeMessage />,
}

export const SalesDetailByProductSort = {
  country: 'country',
  isbn: 'isbn',
  kindName: 'kindName',
  licenseDescription: 'licenseDescription',
  localDLP: 'localDLP',
  localDLPCurrency: 'localDLPCurrency',
  paymentDLP: 'paymentDLP',
  paymentDLPCurrency: 'paymentDLPCurrency',
  productId: 'productId',
  promotionalDiscountPercent: 'promotionalDiscountPercent',
  purchaseModel: 'purchaseModel',
  quantitySold: 'quantitySold',
  earnings: 'earnings',
  earningsCurrency: 'earningsCurrency',
  sales: 'sales',
  salesCurrency: 'salesCurrency',
  salesDate: 'salesDate',
  title: 'title',
  titleId: 'titleId',
  vendorDLP: 'vendorDLP',
  vendorDLPCurrency: 'vendorDLPCurrency',
  vendorMargin: 'vendorMargin',
}

export const SORT_COLUMN = {
  circulations: 'CIRCULATION',
  contentId: 'CONTENT_ID',
  countries: 'COUNTRIES',
  displayDate: 'DISPLAY_DATE',
  earnings: 'EARNING',
  groupTitle: 'GROUP_TITLE',
  id: 'ID',
  intervalDate: 'INTERVAL_DATE',
  kind: 'KIND',
  licenseType: 'LICENSE_TYPE',
  licensedMonths: 'LICENSED_MONTHS',
  maximumCirculations: 'MAXIMUM_CIRCULATIONS',
  publisherId: 'PUBLISHER_ID',
  publisherName: 'PUBLISHER_NAME',
  purchaseMethod: 'PURCHASE_METHOD',
  quantitySold: 'QUANTITY_SOLD',
  releaseDate: 'RELEASE_DATE',
  revenue: 'REVENUE',
  title: 'TITLE',
  vendorContentId: 'VENDOR_CONTENT_ID',
}

export const SORT_DIRECTION = {
  asc: 'ASC',
  desc: 'DESC',
}

export const TITLE_PERFORMANCE_REPORT_TYPE = {
  recent: 'RECENT_ADDITIONS',
  topTrending: 'TOP_TRENDING',
}

export const CIRCULATION_OVERVIEW_DISPLAY = {
  circulations: 'CIRCULATION',
  title: 'TITLE',
}

export const CIRCULATION_DETAIL_GROUP = {
  library: 'LIBRARY',
  product: 'PRODUCT',
}

export const CIRCULATION_DETAIL_SORT = {
  circulations: 'CIRCULATION',
  sold: 'QUANTITY_SOLD',
}

export const REPORT_TYPES = {
  at_a_glance: {
    id: 'AT_A_GLANCE',
    label: <AtAGlanceReportMessage />,
  },
  circulations_overview: {
    id: 'CIRCULATIONS_OVERVIEW',
    label: <SalesOverviewReportMessage />,
  },
  flex_circulations: {
    id: 'FLEX_CIRCULATIONS',
    label: <SalesDetailsReportMessage />,
  },
  inventory: {
    id: 'INVENTORY',
    label: <InventoryReportMessage />,
  },
  title_report: {
    id: 'TITLE_REPORT',
    label: <TitleCirculationReportMessage />,
  },
  title_performance: {
    id: 'TITLE_PERFORMANCE',
    label: <TitlePerformanceReportMessage />,
  },
}

export const CIRCULATIONS_OVERVIEW_FILTERS_STORAGE_KEY = 'hooplaVendor::circulationsOverviewFilters'
export const CIRCULATIONS_HOLDS_FILTERS_STORAGE_KEY = 'hooplaVendor::circulationsHoldsFilters'
export const GLANCE_FILTERS_STORAGE_KEY = 'hooplaVendor::glanceGridFilters'
export const TITLE_PERFORMANCE_FILTERS_STORAGE_KEY = 'hooplaVendor::titlePerformanceFilters'
export const TITLE_CIRCULATIONS_FILTERS_STORAGE_KEY = 'hooplaVendor::titleCirculationFilters'
export const INVENTORY_FILTERS_STORAGE_KEY = 'hooplaVendor::inventoryFilters'
export const SETTINGS_STORAGE_KEY = 'hooplaVendor::settings'
export const TOKEN_STORAGE_KEY = 'hooplaVendor::token'
export const METHOD_STORAGE_KEY = 'hooplaVendor::method'

export const THEMES = {
  dark: 'DARK',
  base: 'BASE',
}

export const NOTIFICATION_TYPES = {
  success: 'SUCCESS',
  warning: 'WARNING',
  info: 'INFO',
  error: 'ERROR',
}

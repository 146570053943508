import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { mediaQuery, themeValue } from '../theme'

export const Navigation = styled.nav`
  display: flex;
  flex-direction: column;

  ${mediaQuery.medium`flex-direction: ${props => props.direction};`}

  /* @TODO[JC]: make this more specific when typography is done */
  > * {
    ${props =>
      props.direction === 'row' &&
      css`
        margin: 0 ${themeValue('space.base')};
      `}

    ${props =>
      props.direction === 'column' &&
      css`
        margin: 0 0 ${themeValue('space.small')};
      `}
  }
`

Navigation.propsTypes = {
  direction: PropTypes.oneOf(['column', 'row']),
}

Navigation.defaultProps = {
  direction: 'row',
}

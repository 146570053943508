import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { AppState } from '../../store/GlobalState'
import { NotificationWarning } from '../../ui'

const MasqueradeNoticeContainer = styled(NotificationWarning)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  border-radius: 0;
`
MasqueradeNoticeContainer.displayName = 'MasqueradeNoticeContainer'

const MasqueradeNotice = () => {
  const {
    settings: { isMasqueradingVendor },
  } = useContext(AppState)

  if (!isMasqueradingVendor) return null

  return (
    <MasqueradeNoticeContainer>
      <FormattedMessage
        id="masqueradeNotice"
        defaultMessage="You are currently viewing this app as if you were a vendor user."
      />
    </MasqueradeNoticeContainer>
  )
}

export default MasqueradeNotice

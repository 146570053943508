import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { UserInfo } from './UserInfo'
import { DesktopMenuContainer, DesktopMenuLink } from 'ui'

export const DesktopMenu = () => {
  return (
    <Fragment>
      <DesktopMenuContainer>
        <DesktopMenuLink to="/dashboard/circulations">
          <FormattedMessage id="desktopMenu.circulations" defaultMessage="Circulations and Sales" />
        </DesktopMenuLink>
        <DesktopMenuLink to="/dashboard/inventory">
          <FormattedMessage id="desktopMenu.inventory" defaultMessage="Inventory" />
        </DesktopMenuLink>
        <DesktopMenuLink to="/dashboard/documents">
          <FormattedMessage id="desktopMenu.documents" defaultMessage="Documents" />
        </DesktopMenuLink>
        <DesktopMenuLink to="/dashboard/users">
          <FormattedMessage id="desktopMenu.users" defaultMessage="Users" />
        </DesktopMenuLink>
        <DesktopMenuLink to="/dashboard/reports">
          <FormattedMessage id="desktopMenu.reports" defaultMessage="Reports" />
        </DesktopMenuLink>
      </DesktopMenuContainer>

      <UserInfo />
    </Fragment>
  )
}

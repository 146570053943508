import { useState, useEffect } from 'react'
import UAParser from 'ua-parser-js'

const engine = new UAParser().getEngine()

// media query to detect dark mode
// preference by user
const mq = window.matchMedia('screen and (prefers-color-scheme: dark)')

// make sure the onChange event is available!
// https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/onchange
const isChangeEventSupported = engine.name === 'Blink' || engine.name === 'Gecko'

const useDarkColorScheme = () => {
  const [prefersDark, setDarkPreference] = useState(mq.matches)

  const onThemeQueryChange = () => {
    setDarkPreference(mq.matches)
  }

  useEffect(() => {
    isChangeEventSupported && mq.addEventListener('change', onThemeQueryChange)

    return () => {
      isChangeEventSupported && mq.removeEventListener('change', onThemeQueryChange)
    }
  }, [])

  return prefersDark
}

export default useDarkColorScheme

import { useState, useRef, useEffect } from 'react'

// Keeps track of current mounted status for the component and only updates state
// if the component is mounted.
export function useSafeSetState(initialState) {
  const [state, setState] = useState(initialState)

  const mountedRef = useRef(false)
  useEffect(() => {
    mountedRef.current = true
    return () => (mountedRef.current = false)
  }, [])

  const safeSetState = (...args) => mountedRef.current && setState(...args)

  return [state, safeSetState]
}

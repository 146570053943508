import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { ErrorBoundary } from '../components/common/ErrorBoundary'
import { Helmet } from 'components/common/Helmet'
import { CustomizedTemplate } from 'components/common/CustomizedTemplate'
import { Main, Footer } from '../components'
import { TopSection, LowerSection, MiddleSection } from '../components/homepage'
import { HomepageHeader } from '../components/homepage/HomepageHeader'
import { Portal } from '../components/common/Portal'
import { ResetPassword } from '../components/common/ResetPassword'
import { NewUserFromAdmin } from '../components/common/NewUserFromAdmin'
import { Background } from '../ui'

export const Homepage = () => {
  const [searchParams] = useSearchParams()

  return (
    <CustomizedTemplate>
      <Helmet title="hoopla Vendor Dashboard" />

      <Background>
        <ErrorBoundary>
          <HomepageHeader />

          <Main>
            <TopSection />
            <MiddleSection />
            <LowerSection />
          </Main>

          <Footer />

          {searchParams.has('resetPasswordToken') && (
            <Portal closeOnEsc defaultOpen={true}>
              <Portal.Content>
                <ResetPassword token={searchParams.get('resetPasswordToken')} />
              </Portal.Content>
            </Portal>
          )}

          {searchParams.has('token') && (
            <Portal closeOnEsc defaultOpen={true}>
              <Portal.Content>
                <NewUserFromAdmin token={searchParams.get('token')} />
              </Portal.Content>
            </Portal>
          )}
        </ErrorBoundary>
      </Background>
    </CustomizedTemplate>
  )
}

import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { mediaQuery, themeValue } from '../theme'
import { fontSize } from '../utils'
import { H3, P } from '../Typography'
import { Background } from '../Background'

//
// Code was taken and modified from styled-css-grid
// See https://styled-css-grid.js.org/
//
//

export const Card = styled(Background)`
  height: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;

  padding: ${themeValue('space.small')};
  ${mediaQuery.medium`padding: ${themeValue('space.base')};`};

  grid-column-end: ${({ width = 1 }) => `span ${width}`};

  grid-row-end: ${({ height = 1 }) => `span ${height}`};

  ${({ left }) => left && `grid-column-start: ${left}`};

  ${({ top }) => top && `grid-row-start: ${top}`};

  ${({ center }) =>
    center &&
    css`
      text-align: center;
      align-items: center;
    `};

  ${({ area }) => area && `grid-area: ${area}`};

  ${({ middle }) =>
    middle &&
    css`
      display: inline-flex;
      flex-flow: column wrap;
      justify-content: center;
      justify-self: stretch;
    `};

  @media print {
    page-break-inside: avoid;
    page-break-after: auto;
    border: 1px solid ${themeValue('colors.secondary.pale')};
  }
`

Card.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  middle: PropTypes.bool,
  center: PropTypes.bool,
  area: PropTypes.string,
  // inherited from Background
  color: PropTypes.string,
  shadow: PropTypes.bool,
}

Card.defaultProps = {
  color: 'colors.background',
  shadow: true,
}

Card.displayName = 'Card'

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${themeValue('space.base')};
`
CardHeader.displayName = 'CardHeader'

export const CardTitle = styled(H3)`
  margin: 0;
  font-weight: ${themeValue('fontWeights.normal')};
`
CardTitle.displayName = 'CardTitle'

export const CardContent = styled.div`
  width: 100%;
`
CardContent.displayName = 'CardContent'

export const CardFooter = styled.div`
  width: 100%;
  margin-top: ${themeValue('space.base')};

  ${P} {
    ${fontSize(2)};
  }
`
CardFooter.displayName = 'CardFooter'

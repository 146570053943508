/**
 * @class Storage
 * @description A pluggable client-side storage class
 */
export default class Storage {
  constructor() {
    this.db = window.localStorage
  }

  /**
   * Save data to the storage database
   *
   * @param {string} key - key associated with data
   * @param {object|array|string} data - data to store
   * @param {number} expiration - expiration time in milliseconds
   * @return {boolean} - true|false to indicate success
   */
  save = (key, data, expiration = null) => {
    try {
      const cache = {
        value: data,
        expiration: expiration ? Date.now() + expiration : -1,
      }
      this.db.setItem(key.toString(), JSON.stringify(cache))
      return true
    } catch (_) {
      return false
    }
  }

  /**
   * Load data from the storage database
   *
   * @param {string} key - the key to load
   * @return {object} - the stored data, or null if
   *    the data does not exist or has expired
   */
  load = (key) => {
    try {
      const cache = this.db.getItem(key)
      if (!cache) return null

      const data = JSON.parse(cache)
      if (data && data.expiration >= 0 && data.expiration < Date.now()) {
        this.remove(key)
        return null
      } else if (data) {
        return data.value
      } else {
        return null
      }
    } catch (_) {
      return null
    }
  }

  /**
   * Remove a stored item
   *
   * @param {string} key - the key to remove
   * @return {boolean} - true|false to indicate success
   */
  remove = (key) => {
    try {
      this.db.removeItem(key)
      return true
    } catch (_) {
      return false
    }
  }

  /**
   * Delete all stored data
   *
   * @return {boolean} - true|false to indicate success
   */
  clear = () => {
    try {
      this.db.clear()
      return true
    } catch (_) {
      return false
    }
  }
}

import styled, { css } from 'styled-components'

import { mediaQuery, themeValue } from '../theme'
import { BrandingContainer } from '../Branding'
import { Inset } from '../Inset'

export const LayoutHeader = styled.header`
  background-color: ${themeValue('colors.background')};
  color: ${themeValue('colors.foreground')};
  padding: ${themeValue('space.small')} 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 15;

  ${BrandingContainer} {
    height: ${themeValue('space.medium')};
  }

  ${mediaQuery.medium`padding: 0;`}
`
LayoutHeader.displayName = 'LayoutHeader'

export const LayoutHeaderInner = styled(Inset)`
  display: flex;

  ${mediaQuery.medium &&
    css`
      justify-content: space-between;
      align-items: center;
    `}
`
LayoutHeaderInner.displayName = 'LayoutHeaderInner'

import styled, { css } from 'styled-components'

import { mediaQuery, themeValue } from '../theme'
import { fontSize, visuallyHidden } from '../utils'
import { ButtonGroup, IconButton } from '../Button'

export const FormLabel = styled.label`
  ${fontSize(1)};
  margin-bottom: ${themeValue('space.tiny')};
  display: block;
  font-weight: ${themeValue('fontWeights.bold')};

  ${props =>
    props.disabled === true &&
    css`
      color: ${themeValue('colors.secondary.highlight')};
    `};

  ${props => props.visuallyHidden && visuallyHidden()};
`
FormLabel.displayName = 'FormLabel'

export const FormRequiredNotice = styled.span`
  ${fontSize(0)};
  display: inline-block;
  margin-left: ${themeValue('space.tiny')};
`
FormRequiredNotice.displayName = 'FormRequiredNotice'

export const FormError = styled.span`
  ${fontSize(0)};
  color: ${themeValue('colors.error.base')};
  display: block;
  margin-top: ${themeValue('space.tiny')};
`
FormError.displayName = 'FormError'

export const FormGroup = styled.div`
  margin-bottom: ${themeValue('space.small')};
  width: 100%;

  ${props =>
    props.highlight &&
    css`
      background-color: ${themeValue('colors.backgroundDark')};
      padding: 0 0.2em 0.3em;
    `};
`
FormGroup.displayName = 'FormGroup'

export const Form = styled.form`
  margin: 0;
  width: 100%;
`
Form.displayName = 'Form'

export const FormInline = styled(Form)`
  display: flex;
  flex-direction: column;

  ${mediaQuery.mediumLarge`
    flex-direction: row;
    align-items: flex-end;
  `};

  ${FormLabel} {
    padding-right: ${themeValue('space.base')};
  }

  ${FormGroup} {
    margin: 0 ${themeValue('space.small')} 0 0;
    display: inline-block;
    width: auto;

    &:last-child {
      margin: 0;
    }
  }

  ${ButtonGroup} {
    margin: 0;
  }

  ${IconButton} {
    margin-top: calc(${themeValue('space.base')} + ${themeValue('space.tiny')});
  }
`
FormInline.displayName = 'FormInline'

import React, { Fragment, useEffect, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Formik } from 'formik'
import { Mutation } from '@apollo/client/react/components'

import { CONTACT_INFO } from 'store/queries'
import { AppState } from '../../store/GlobalState'
import { makeValidator } from 'utils/form'
import {
  ButtonGroup,
  Form,
  NotificationError,
  NotificationSuccess,
  FormError,
  FormGroup,
  FormLabel,
  FormRequiredNotice,
  Input,
  Modal,
  PrimaryButton,
  SecondaryButton,
} from '../../ui'
import { RequiredMessage } from './Messages'

const constraints = {
  email: {
    presence: true,
    email: true,
  },
  name: {
    presence: true,
  },
}

export const ContactModal = ({ closePortal }) => {
  const {
    settings: { user },
  } = useContext(AppState)

  useEffect(() => {
    document.getElementById('name').focus()
    return undefined
  }, [])

  return (
    <Modal
      closeModal={closePortal}
      title={<FormattedMessage id="modals.contact.title" defaultMessage="Learn about becoming a hoopla Vendor" />}>
      <Mutation mutation={CONTACT_INFO}>
        {(requestInfo, { error }) => (
          <Formik
            initialValues={{
              email: user ? user.email : '',
              name: '',
              title: '',
              company: '',
            }}
            onSubmit={async ({ name, company, title, email }, { setStatus, setSubmitting }) => {
              setStatus(null)

              try {
                await requestInfo({ variables: { name, company, title, email } })

                setStatus({
                  success: (
                    <FormattedMessage
                      id="modals.contact.success"
                      defaultMessage="Thanks for reaching out! Someone from our team will be in contact soon."
                      values={{ name }}
                    />
                  ),
                })
              } catch (ex) {
                setStatus({ failed: ex.message })
              } finally {
                setSubmitting(false)
              }
            }}
            validate={makeValidator(constraints)}>
            {({ dirty, errors, handleBlur, handleChange, handleSubmit, isSubmitting, status, touched, values }) => (
              <Form onSubmit={handleSubmit}>
                {error && (
                  <FormGroup>
                    <NotificationError>
                      <FormattedMessage
                        id="modals.contact.failed"
                        defaultMessage="We're sorry. Please try again later."
                      />
                    </NotificationError>
                  </FormGroup>
                )}

                {status && status.failed && (
                  <FormGroup>
                    <NotificationError>{status.failed}</NotificationError>
                  </FormGroup>
                )}

                {status && status.success && (
                  <FormGroup>
                    <NotificationSuccess>{status.success}</NotificationSuccess>
                  </FormGroup>
                )}

                {!(status && status.success) && (
                  <Fragment>
                    <FormGroup>
                      <FormLabel htmlFor="name">
                        <FormattedMessage id="modals.contact.form.name.label" defaultMessage="Name" />
                        {!touched.name && values.name === '' && (
                          <FormRequiredNotice>
                            <RequiredMessage />
                          </FormRequiredNotice>
                        )}
                      </FormLabel>
                      <FormattedMessage id="modals.contact.form.name.placeholder" defaultMessage="Enter your name">
                        {placeholder => (
                          <Input
                            id="name"
                            required
                            placeholder={placeholder}
                            hasError={touched.name && errors.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                          />
                        )}
                      </FormattedMessage>
                      {touched.name && errors.name && <FormError>{errors.name}</FormError>}
                    </FormGroup>

                    <FormGroup>
                      <FormLabel htmlFor="title">
                        <FormattedMessage id="modals.contact.form.username.title" defaultMessage="Title" />
                      </FormLabel>
                      <FormattedMessage
                        id="modals.contact.form.title.placeholder"
                        defaultMessage="Enter your title (if any)">
                        {placeholder => (
                          <Input
                            id="title"
                            placeholder={placeholder}
                            hasError={touched.title && errors.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                          />
                        )}
                      </FormattedMessage>
                      {touched.title && errors.title && <FormError>{errors.title}</FormError>}
                    </FormGroup>

                    <FormGroup>
                      <FormLabel htmlFor="company">
                        <FormattedMessage id="modals.contact.form.company.label" defaultMessage="Company" />
                      </FormLabel>
                      <FormattedMessage
                        id="modals.contact.form.company.placeholder"
                        defaultMessage="Enter your company name (if any)">
                        {placeholder => (
                          <Input
                            id="company"
                            placeholder={placeholder}
                            hasError={touched.company && errors.company}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company}
                          />
                        )}
                      </FormattedMessage>
                      {touched.company && errors.company && <FormError>{errors.company}</FormError>}
                    </FormGroup>

                    <FormGroup>
                      <FormLabel htmlFor="email">
                        <FormattedMessage id="modals.contact.form.email.label" defaultMessage="Email" />
                        {!touched.email && values.email === '' && (
                          <FormRequiredNotice>
                            <RequiredMessage />
                          </FormRequiredNotice>
                        )}
                      </FormLabel>
                      <FormattedMessage id="modals.contact.form.email.placeholder" defaultMessage="Enter your address">
                        {placeholder => (
                          <Input
                            id="email"
                            type="email"
                            required
                            placeholder={placeholder}
                            hasError={touched.email && errors.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                        )}
                      </FormattedMessage>
                      {touched.email && errors.email && <FormError>{errors.email}</FormError>}
                    </FormGroup>

                    <ButtonGroup align="flex-end">
                      <PrimaryButton disabled={!dirty || isSubmitting || Object.keys(errors).length} type="submit">
                        <FormattedMessage id="modals.contact.form.button.submit" defaultMessage="Send me information" />
                      </PrimaryButton>
                      <SecondaryButton type="button" onClick={closePortal}>
                        <FormattedMessage id="modals.contact.form.button.cancel" defaultMessage="Cancel" />
                      </SecondaryButton>
                    </ButtonGroup>
                  </Fragment>
                )}
              </Form>
            )}
          </Formik>
        )}
      </Mutation>
    </Modal>
  )
}

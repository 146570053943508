import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { themeValue } from '../theme'
import { fontSize } from '../utils'
import { Navigation } from '../Navigation'

export const DesktopMenuLink = styled(Link)`
  ${fontSize(2)};
  color: ${themeValue('colors.foreground')};
  border-bottom: 3px solid transparent;
  text-decoration: none;
  padding: ${themeValue('space.base')} ${themeValue('space.small')};

  &[aria-current='page'] {
    color: ${themeValue('colors.primary.highlight')};
    border-bottom-color: ${themeValue('colors.accent.highlight')};
  }

  &:hover,
  &:focus {
    outline: none;
    color: ${themeValue('colors.primary.highlight')};
    border-bottom-color: ${themeValue('colors.accent.highlight')};
  }
`
DesktopMenuLink.displayName = 'DesktopMenuLink'

export const DesktopMenuContainer = styled(Navigation)`
  > * {
    margin: 0;
  }
`
DesktopMenuContainer.displayName = 'DesktopMenuContainer'

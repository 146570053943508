import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { space } from '../utils'
import { mediaQuery, themeValue } from '../theme'
import { Icon } from '../Icon'
import { H3 } from '../Typography'
import { ButtonGroup, IconButton } from '../Button'

export const ModalBody = styled.article`
  padding: ${themeValue('space.base')};
  background-color: ${themeValue('colors.background')};
  width: 100vw;
  height: 100%;
  ${props =>
    !props.allowContentOverflow &&
    css`
      overflow-y: scroll;
    `};

  ${mediaQuery.medium`
    width: ${space(24)};
    height: auto;
    border-radius: ${themeValue('borderRadius')};
    margin: auto;
    max-height: 100vh;
  `}
`
ModalBody.displayName = 'ModalBody'

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${H3} {
    max-width: 90%;
  }
`
ModalHeader.displayName = 'ModalHeader'

export const ModalContent = styled.div`
  margin: ${themeValue('space.base')} 0 0;

  ${ButtonGroup} {
    margin: ${themeValue('space.base')} 0 0;
  }
`
ModalContent.displayName = 'ModalContent'

export const Modal = ({ title, children, closeModal, allowContentOverflow }) => (
  <div style={{ display: 'flex', height: '100%' }}>
    <ModalBody allowContentOverflow={allowContentOverflow}>
      <ModalHeader>
        <H3>{title}</H3>
        <IconButton onClick={closeModal}>
          <Icon type="close" />
        </IconButton>
      </ModalHeader>
      <ModalContent>{children}</ModalContent>
    </ModalBody>
  </div>
)

Modal.propTypes = {
  allowContentOverflow: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  closeModal: PropTypes.func.isRequired,
}

Modal.defaultProps = {
  allowContentOverflow: false,
}

import styled from 'styled-components'
import PropTypes from 'prop-types'

import { themeValue } from 'ui'

export const Svg = styled.svg.attrs({
  viewBox: '0 0 1835 722',
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
})`
  display: block;
`
Svg.displayName = 'Svg'

export const SvgGroup = styled.g`
  fill: ${props => themeValue(props.color)};
  stroke: none;
  stroke-width: 1;
  fill-rule: evenodd;
`
SvgGroup.displayName = 'HooplaLogoPath'
SvgGroup.propTypes = {
  color: PropTypes.string,
}
SvgGroup.defaultProps = {
  color: 'colors.always.black',
}

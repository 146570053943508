import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { mediaQuery, themeValue } from '../theme'
import { fontSize } from '../utils'
import { H3, P } from '../Typography'
import { InsetInner } from '../Inset'

export const StatisticValue = styled(H3)`
  color: ${themeValue('colors.primary.base')};
  ${fontSize(3)};
  margin: 0;
`
StatisticValue.displayName = 'StatisticValue'

export const StatisticLabel = styled(P)``
StatisticLabel.displayName = 'StatisticLabel'

export const StatisticCallout = styled(H3)`
  ${fontSize(2)};
  font-weight: ${themeValue('fontWeights.normal')};

  ${mediaQuery.medium`${fontSize(2)}`}
`
StatisticCallout.displayName = 'StatisticCallout'

export const StatisticFooter = styled(InsetInner).attrs({
  width: '90%',
})`
  display: flex;
  flex-direction: column;
  margin-top: ${themeValue('space.small')};

  ${mediaQuery.medium`
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
  `};
`
StatisticFooter.displayName = 'StatisticFooter'

export const StatisticMeta = styled(P)`
  white-space: nowrap;

  & + & {
    margin: 0 0 0 ${themeValue('space.small')};
  }
`
StatisticMeta.displayName = 'StatisticMeta'

export const Statistic = styled.div`
  text-align: center;
  width: 100%;

  ${props =>
    props.size === 'large' &&
    css`
      ${mediaQuery.medium`
      ${StatisticValue} {
        ${fontSize(4)};
        margin-bottom: ${themeValue('space.tiny')};
      }
      ${StatisticLabel} {
        ${fontSize(2)};
      }`};
    `};
`
Statistic.displayName = 'Statistic'

Statistic.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
}

Statistic.defaultProps = {
  size: 'large',
}

export const StatisticList = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex: 1;
  width: 100%;

  ${props =>
    props.columns &&
    css`
      flex-wrap: wrap;
    `};

  ${Statistic} {
    display: inline-flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-self: stretch;
    padding: 0 ${themeValue('space.tiny')};

    ${props =>
      props.columns &&
      css`
        flex-basis: ${100 / props.columns}%;

        &:nth-child(n + 3) {
          margin-top: ${themeValue('space.small')};
        }
      `};
  }
`
StatisticList.displayName = 'StatisticList'

import styled, { css } from 'styled-components'

import { themeValue } from '../theme'
import { formElementBase } from '../utils'

export const Textarea = styled.textarea`
  ${formElementBase()};
  border: 1px solid ${themeValue('colors.secondary.highlight')};
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;

  &:disabled {
    color: ${themeValue('colors.secondary.highlight')};
    border: 1px solid ${themeValue('colors.secondary.highlight')};
    &::placeholder {
      color: ${themeValue('colors.secondary.highlight')};
    }
  }

  ${props =>
    props.hasError &&
    css`
      border-color: ${themeValue('colors.error.base')};
    `}
`
Textarea.displayName = 'Textarea'

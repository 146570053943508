import { useReducer } from 'react'
import orderBy from 'lodash/orderBy'
import toUpper from 'lodash/toUpper'
import toNumber from 'lodash/toNumber'

const LIMIT = 50
export const PAGER_SET_DATA = 'usePagination::PAGER_SET_DATA'
export const PAGER_GOTO = 'usePagination::PAGER_GOTO'
export const PAGER_SORT = 'usePagination::PAGER_SORT'

const getNextPageNumber = (currentPage, totalPages) => (currentPage === totalPages ? 1 : currentPage + 1)

const getPreviousPageNumber = (currentPage, totalPages) => (currentPage === 1 ? totalPages : currentPage - 1)

const getStartCount = currentPage => (currentPage - 1) * LIMIT

const getPageData = (currentPage, data) => data.slice((currentPage - 1) * LIMIT, (currentPage - 1) * LIMIT + LIMIT)

const getPageCount = pageData => (pageData > LIMIT ? LIMIT : pageData.length)

const sortData = (collection, property, sortDirection) => {
  if (Number.isFinite(collection[0][property]) || /^\d+[.]?\d+$/.test(collection[0][property])) {
    const coerced = collection.map(row => ({ ...row, [property]: toNumber(row[property]) }))
    return orderBy(coerced, property, sortDirection.toLowerCase())
  } else {
    return orderBy(collection, [item => toUpper(item[property])], sortDirection.toLowerCase())
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case PAGER_SET_DATA: {
      const data = sortData(action.payload.data, state.sortColumn, state.sortDirection)
      const currentPage = state.currentPage
      const totalPages = Math.ceil(data.length / LIMIT)
      const pageData = getPageData(currentPage, data)

      return {
        ...state,
        currentPage,
        totalPages: totalPages,
        nextPage: getNextPageNumber(currentPage, totalPages),
        previousPage: getPreviousPageNumber(currentPage, totalPages),
        startCount: getStartCount(currentPage), // # of first item on page
        totalCount: data.length,
        pageCount: getPageCount(pageData),
        allData: data,
        pageData,
      }
    }

    case PAGER_GOTO: {
      const { page } = action.payload
      const pageData = getPageData(page, state.allData)

      return {
        ...state,
        currentPage: page,
        nextPage: getNextPageNumber(page, state.totalPages),
        previousPage: getPreviousPageNumber(page, state.totalPages),
        startCount: getStartCount(page),
        pageData,
        pageCount: getPageCount(pageData),
      }
    }

    case PAGER_SORT: {
      const { sortColumn, sortDirection } = action.payload
      const sorted = sortData(state.allData, sortColumn, sortDirection)
      const pageData = getPageData(1, sorted)

      return {
        ...state,
        sortColumn,
        sortDirection,
        currentPage: 1,
        nextPage: getNextPageNumber(1, state.totalPages),
        previousPage: getPreviousPageNumber(1, state.totalPages),
        startCount: getStartCount(1),
        pageData,
        allData: sorted,
        pageCount: getPageCount(pageData),
      }
    }

    default:
      throw new Error()
  }
}

const initPager = ({ data, currentPage, sortColumn, sortDirection }) => {
  const totalPages = Math.ceil(data.length / LIMIT)
  const pageData = getPageData(currentPage, data)

  return {
    sortColumn,
    sortDirection,
    currentPage,
    totalPages: totalPages,
    nextPage: getNextPageNumber(currentPage, totalPages),
    previousPage: getPreviousPageNumber(currentPage, totalPages),
    startCount: getStartCount(currentPage), // # of first item on page
    totalCount: data.length,
    pageCount: getPageCount(pageData),
    allData: data,
    pageData,
  }
}

export const usePagination = (data, currentPage = 1, sortColumn, sortDirection = 'DESC') => {
  const [pager, setPager] = useReducer(reducer, { data, currentPage, sortColumn, sortDirection }, initPager)

  return [pager, setPager]
}

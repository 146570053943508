import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Mutation } from '@apollo/client/react/components'
import { Formik } from 'formik'

import { FORGOT_PASSWORD } from '../../store/queries'
import { makeValidator } from 'utils/form'

import {
  ButtonGroup,
  Form,
  FormError,
  FormGroup,
  FormRequiredNotice,
  NotificationError,
  NotificationSuccess,
  FormLabel,
  Input,
  Modal,
  PrimaryButton,
  SecondaryButton,
} from 'ui'
import { RequiredMessage } from './Messages'

const constraints = {
  loginEmail: {
    presence: true,
    email: true,
  },
}

export const ForgotPassword = ({ closePortal }) => (
  <Modal
    closeModal={closePortal}
    title={<FormattedMessage id="modals.forgotPassword.title" defaultMessage="Reset your password" />}>
    <Mutation mutation={FORGOT_PASSWORD}>
      {forgotPassword => (
        <Formik
          initialValues={{
            loginEmail: '',
          }}
          onSubmit={async ({ loginEmail }, { setStatus, setSubmitting }) => {
            try {
              await forgotPassword({ variables: { email: loginEmail } })
              setStatus({
                success: (
                  <FormattedMessage
                    id="modals.forgotPassword.success"
                    defaultMessage="Please check your email for instructions on resetting your password."
                  />
                ),
              })
            } catch (ex) {
              setStatus({ failed: ex.message.replace(/GraphQL error: /, '') })
            } finally {
              setSubmitting(false)
            }
          }}
          validate={makeValidator(constraints)}>
          {({ dirty, errors, handleBlur, handleChange, handleSubmit, isSubmitting, status, touched, values }) => (
            <Form onSubmit={handleSubmit}>
              {status && status.failed && (
                <FormGroup>
                  <NotificationError>{status.failed}</NotificationError>
                </FormGroup>
              )}

              {status && status.success && (
                <FormGroup>
                  <NotificationSuccess>{status.success}</NotificationSuccess>
                </FormGroup>
              )}

              <FormGroup>
                <FormLabel htmlFor="loginEmail">
                  <FormattedMessage id="modals.forgotPassword.form.loginEmail.label" defaultMessage="Email" />
                  {!touched.loginEmail && values.loginEmail === '' && (
                    <FormRequiredNotice>
                      <RequiredMessage />
                    </FormRequiredNotice>
                  )}
                </FormLabel>
                <FormattedMessage
                  id="modals.forgotPassword.form.loginEmail.placeholder"
                  defaultMessage="Enter your address">
                  {placeholder => (
                    <Input
                      id="loginEmail"
                      type="email"
                      required
                      placeholder={placeholder}
                      hasError={touched.loginEmail && errors.loginEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.loginEmail}
                    />
                  )}
                </FormattedMessage>
                {touched.loginEmail && errors.loginEmail && <FormError>{errors.loginEmail}</FormError>}
              </FormGroup>

              <ButtonGroup align="flex-end">
                <PrimaryButton disabled={!dirty || isSubmitting || Object.keys(errors).length} type="submit">
                  <FormattedMessage id="modals.forgotPassword.form.button.submit" defaultMessage="Reset" />
                </PrimaryButton>
                <SecondaryButton type="button" onClick={closePortal}>
                  <FormattedMessage id="modals.forgotPassword.form.button.cancel" defaultMessage="Cancel" />
                </SecondaryButton>
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      )}
    </Mutation>
  </Modal>
)

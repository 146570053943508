import styled, { css } from 'styled-components'
import posed from 'react-pose'

import { themeValue } from '../theme'
import { fontSize, space } from '../utils'
import { BareButton } from '../Button'

export const DropdownToggle = styled(BareButton)`
  ${fontSize(1)};
  border: 1px solid ${themeValue('colors.secondary.highlight')};
  border-radius: ${themeValue('borderRadius')};
  padding: ${space(0.15)} ${themeValue('space.small')};
  background-color: ${themeValue('colors.background')};
  text-transform: none;
  font-weight: ${themeValue('fontWeights.normal')};

  &:focus,
  &:hover {
    background-color: ${themeValue('colors.backgroundDark')};
    outline: none;
    box-shadow: 0 0 1px ${themeValue('overlayColors.primary')};
  }
`
DropdownToggle.displayName = 'DropdownToggle'

const StyledDropdownMenu = styled.ul`
  margin: 0;
  position: absolute;
  top: ${themeValue('space.medium')};
  right: 0;
  width: 18rem;
  padding: ${themeValue('space.tiny')};
  z-index: 10;
  background-color: ${themeValue('colors.background')};
  border: 1px solid ${themeValue('colors.secondary.highlight')};
  border-radius: ${themeValue('borderRadius')};

  ${props =>
    !props.isActive &&
    css`
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px, 1px, 1px, 1px);
    `};
`
StyledDropdownMenu.displayName = 'StyledDropdownMenu'

export const DropdownMenu = posed(StyledDropdownMenu)({
  open: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: { duration: 300 },
  },
  closed: {
    y: -15,
    opacity: 0,
    transition: { duration: 100 },
  },
})
DropdownMenu.displayName = 'DropdownMenu'

export const DropdownMenuItem = styled.li`
  & + & {
    margin-top: ${themeValue('space.tiny')};
  }

  ${BareButton} {
    width: 100%;

    &:focus,
    &:hover {
      background-color: ${themeValue('colors.backgroundDark')};
    }
  }

  svg {
    margin-right: ${themeValue('space.small')};
  }
`
DropdownMenuItem.displayName = 'DropdownMenuItem'

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`
DropdownContainer.displayName = 'DropdownContainer'

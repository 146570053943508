import styled from 'styled-components'

import { themeValue } from 'ui'

export const MobileMenuContainer = styled.div`
  background-color: ${themeValue('colors.background')};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: ${themeValue('space.base')};
  text-align: right;
`
MobileMenuContainer.displayName = 'MobileMenuContainer'

export const MobileMenuSection = styled.div`
  margin-top: ${themeValue('space.base')};

  & + & {
    padding-top: ${themeValue('space.base')};
    border-top: 1px solid ${themeValue('colors.secondary.highlight')};
  }
`
MobileMenuSection.displayName = 'MobileMenuSection'

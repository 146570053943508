import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { HttpLink, InMemoryCache, ApolloClient } from '@apollo/client'

import Storage from './Storage'
import {
  METHOD_STORAGE_KEY,
  SETTINGS_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
  CIRCULATIONS_OVERVIEW_FILTERS_STORAGE_KEY,
  CIRCULATIONS_HOLDS_FILTERS_STORAGE_KEY,
  GLANCE_FILTERS_STORAGE_KEY,
  TITLE_PERFORMANCE_FILTERS_STORAGE_KEY,
  TITLE_CIRCULATIONS_FILTERS_STORAGE_KEY,
  INVENTORY_FILTERS_STORAGE_KEY,
} from './constants'

const storage = new Storage()

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_WS_ENDPOINT}/graphql`,
})

const withToken = setContext(() => {
  // docs: https://www.apollographql.com/docs/link/links/context.html
  const token = storage.load(TOKEN_STORAGE_KEY)
  const settings = storage.load(SETTINGS_STORAGE_KEY)
  const method = window.localStorage.getItem(METHOD_STORAGE_KEY)

  const headers = {}

  headers['circulation-method'] = method ? method.toUpperCase() : 'INSTANT'
  headers['masquerade-vendor'] = settings && settings.isMasqueradingVendor ? 1 : 0
  if (token && token.value) {
    headers.authorization = `Bearer ${token.value}`
  }

  return {
    includeExtensions: true,
    headers,
  }
})

const withErrors = onError(({ operation, graphQLErrors, networkError, response }) => {
  // docs: https://www.apollographql.com/docs/link/links/error.html
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      console.log(`[GraphQL error]: ${message} -- during ${operation.operationName} operation`)

      switch (message) {
        case 'invalid token': {
          // @TODO: if there was an invalid token error, go to homepage
          storage.remove(TOKEN_STORAGE_KEY)
          storage.remove(CIRCULATIONS_OVERVIEW_FILTERS_STORAGE_KEY)
          storage.remove(CIRCULATIONS_HOLDS_FILTERS_STORAGE_KEY)
          storage.remove(GLANCE_FILTERS_STORAGE_KEY)
          storage.remove(TITLE_PERFORMANCE_FILTERS_STORAGE_KEY)
          storage.remove(TITLE_CIRCULATIONS_FILTERS_STORAGE_KEY)
          storage.remove(INVENTORY_FILTERS_STORAGE_KEY)
          window.location.href = '/'
          console.log('possible invalid token error', operation)
          return null
        }
        default: {
          return null
        }
      }
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    // @TODO: try to find some better way to handle this
    // If express failed to validate the JWT (i.e. outside of Apollo on the backend), then
    // you will land here.
    // if (networkError.indexOf('invalid token') >= 0) {
    //   return storage.remove(USER_STORAGE_KEY)
    // }
  }
})

export const client = new ApolloClient({
  link: withErrors.concat(withToken.concat(httpLink)),
  cache: new InMemoryCache(),
})

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Background } from 'ui'
import { Overlay } from './Overlay'

const KEYCODES = {
  ESCAPE: 27,
}

export const PortalContext = React.createContext()

export const Portal = ({ children, closeOnEsc, defaultOpen }) => {
  const [isShowing, togglePortal] = useState(defaultOpen)

  useEffect(() => {
    closeOnEsc && window.addEventListener('keydown', handleKeydown)

    return () => {
      closeOnEsc && window.removeEventListener('keydown', handleKeydown)
    }
  }, [closeOnEsc])

  const openPortal = e => {
    if (e && e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation()
    }
    togglePortal(true)
    document.querySelectorAll('body')[0].style.position = 'fixed'
  }

  const closePortal = () => {
    togglePortal(false)
    document.querySelectorAll('body')[0].style.position = 'static'
  }

  const handleKeydown = e => {
    if (e.keyCode === KEYCODES.ESCAPE) {
      togglePortal(false)
    }
  }

  return (
    <PortalContext.Provider
      value={{
        isShowing,
        togglePortal,
        openPortal,
        closePortal,
      }}>
      {children}
    </PortalContext.Provider>
  )
}

function PortalContent({ children }) {
  return (
    <PortalContext.Consumer>
      {({ id, isShowing, closePortal, openPortal, togglePortal }) => {
        const handleClose = e => e.currentTarget === e.target && closePortal()
        if (isShowing) {
          return (
            <Overlay id={id}>
              <Background color="overlayColors.primary" fullsize onMouseDown={handleClose}>
                {React.Children.map(children, child =>
                  React.cloneElement(child, {
                    closePortal,
                    isShowing,
                    openPortal,
                    togglePortal,
                  }),
                )}
              </Background>
            </Overlay>
          )
        } else {
          return null
        }
      }}
    </PortalContext.Consumer>
  )
}

Portal.Content = PortalContent
Portal.Toggle = PortalContext.Consumer

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  closeOnEsc: PropTypes.bool,
  defaultOpen: PropTypes.bool,
}

Portal.defaultProps = {
  defaultOpen: false,
}

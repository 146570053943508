import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import { BrandingContainer } from 'ui'
import Logo from './hoopla-login-logo.png'

export const Branding = () => (
  <BrandingContainer>
    <FormattedMessage id="header.logo.label" defaultMessage="Go to dashboard">
      {label => (
        <Link to="/dashboard" aria-label={label}>
          <FormattedMessage id="header.logo.alt" defaultMessage="hoopla logo">
            {alt => <img src={Logo} alt={alt} aria-hidden="true" />}
          </FormattedMessage>
        </Link>
      )}
    </FormattedMessage>
  </BrandingContainer>
)

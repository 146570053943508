import React from 'react'
import { FormattedMessage } from 'react-intl'

export const AllPublishersMessage = ({ children }) => (
  <FormattedMessage id="messages.allPublishers" defaultMessage="All publishers">
    {children}
  </FormattedMessage>
)

export const AllFormatsMessage = ({ children }) => (
  <FormattedMessage id="messages.allFormats" defaultMessage="All formats">
    {children}
  </FormattedMessage>
)

export const PublisherMessage = ({ children }) => (
  <FormattedMessage id="messages.publisher" defaultMessage="Publisher">
    {children}
  </FormattedMessage>
)

export const StartDateMessage = ({ children }) => (
  <FormattedMessage id="messages.startDate" defaultMessage="Start date">
    {children}
  </FormattedMessage>
)

export const EndDateMessage = ({ children }) => (
  <FormattedMessage id="messages.endDate" defaultMessage="End date">
    {children}
  </FormattedMessage>
)

export const NewTitlesMessage = ({ children }) => (
  <FormattedMessage id="messages.newTitles" defaultMessage="New titles">
    {children}
  </FormattedMessage>
)

export const TrendingTitlesMessage = ({ children }) => (
  <FormattedMessage id="messages.trendingTitles" defaultMessage="Top performing titles">
    {children}
  </FormattedMessage>
)

export const PerformingTitlesMessage = ({ children }) => (
  <FormattedMessage id="messages.topPerformingTitles" defaultMessage="Top performing titles">
    {children}
  </FormattedMessage>
)

export const RecentAdditionsTitlesMessage = ({ children }) => (
  <FormattedMessage id="messages.recentlyAddedTitles" defaultMessage="Recently added titles">
    {children}
  </FormattedMessage>
)

export const CirculationsMessage = ({ children }) => (
  <FormattedMessage id="messages.circs" defaultMessage="Circulations">
    {children}
  </FormattedMessage>
)

export const EarningsMessage = ({ children }) => (
  <FormattedMessage id="messages.earnings" defaultMessage="Net earning">
    {children}
  </FormattedMessage>
)

export const RevenueMessage = ({ children }) => (
  <FormattedMessage id="messages.revenue" defaultMessage="Revenue">
    {children}
  </FormattedMessage>
)

export const TitleMessage = ({ children }) => (
  <FormattedMessage id="messages.title" defaultMessage="Title">
    {children}
  </FormattedMessage>
)

export const GroupTitleMessage = ({ children }) => (
  <FormattedMessage id="messages.groupTitle" defaultMessage="Group title">
    {children}
  </FormattedMessage>
)

export const ModelMessage = ({ children }) => (
  <FormattedMessage id="messages.model" defaultMessage="Model">
    {children}
  </FormattedMessage>
)

export const FormatMessage = ({ children }) => (
  <FormattedMessage id="messages.format" defaultMessage="Format">
    {children}
  </FormattedMessage>
)

export const AllTimeMessage = ({ children }) => (
  <FormattedMessage id="messages.allTime" defaultMessage="All">
    {children}
  </FormattedMessage>
)

export const TodayTimeMessage = ({ children }) => (
  <FormattedMessage id="messages.todayTime" defaultMessage="Today">
    {children}
  </FormattedMessage>
)

export const YesterdayTimeMessage = ({ children }) => (
  <FormattedMessage id="messages.yesterday" defaultMessage="Previous day">
    {children}
  </FormattedMessage>
)

export const LastMonthTimeMessage = ({ children }) => (
  <FormattedMessage id="messages.lastMonthTime" defaultMessage="Last month">
    {children}
  </FormattedMessage>
)

export const QuarterTimeMessage = ({ children }) => (
  <FormattedMessage id="messages.quarterTime" defaultMessage="Last three months">
    {children}
  </FormattedMessage>
)

export const LastWeekTimeMessage = ({ children }) => (
  <FormattedMessage id="messages.weekTime" defaultMessage="Last week">
    {children}
  </FormattedMessage>
)

export const TimeFrameMessage = ({ children }) => (
  <FormattedMessage id="messages.timeframe" defaultMessage="Time frame">
    {children}
  </FormattedMessage>
)

export const CustomRangeMessage = ({ children }) => (
  <FormattedMessage id="messages.customTime" defaultMessage="Custom dates">
    {children}
  </FormattedMessage>
)

export const RunReportMessage = ({ children }) => (
  <FormattedMessage id="messages.runReport" defaultMessage="Refresh report">
    {children}
  </FormattedMessage>
)

export const NoSearchResultsMessage = ({ children }) => (
  <FormattedMessage id="messages.noSearchResults" defaultMessage="There were no results matching the selected filters.">
    {children}
  </FormattedMessage>
)

export const YesMessage = ({ children }) => (
  <FormattedMessage id="messages.yes" defaultMessage="Yes">
    {children}
  </FormattedMessage>
)

export const NoMessage = ({ children }) => (
  <FormattedMessage id="messages.no" defaultMessage="No">
    {children}
  </FormattedMessage>
)

export const AllMessage = ({ children }) => (
  <FormattedMessage id="messages.all" defaultMessage="All">
    {children}
  </FormattedMessage>
)

export const FlexMessage = ({ children }) => (
  <FormattedMessage id="messages.est" defaultMessage="Flex">
    {children}
  </FormattedMessage>
)

export const InstantMessage = ({ children }) => (
  <FormattedMessage id="messages.ppu" defaultMessage="Instant">
    {children}
  </FormattedMessage>
)

export const ChillOutMessage = ({ children }) => (
  <FormattedMessage
    id="messages.chillout"
    defaultMessage="Please wait. We're crunching numbers, and will get your data back soon.">
    {children}
  </FormattedMessage>
)

export const QuantitySoldMessage = ({ children }) => (
  <FormattedMessage id="messages.quantitySold" defaultMessage="Flex quantity sold">
    {children}
  </FormattedMessage>
)

export const MaximumReportResultsMessage = ({ children }) => (
  <FormattedMessage
    id="messages.MaxReportResults"
    defaultMessage="Showing a maximum of the top 50 results matching the current filters.">
    {children}
  </FormattedMessage>
)

export const DailyFrequencyMessage = ({ children }) => (
  <FormattedMessage id="messages.dailyFrequency" defaultMessage="Daily. Sent every day at 9:00am UTC.">
    {children}
  </FormattedMessage>
)

export const WeeklyFrequencyMessage = ({ children }) => (
  <FormattedMessage id="messages.weeklyFrequency" defaultMessage="Weekly. Sent every Monday at 9:00am UTC.">
    {children}
  </FormattedMessage>
)

export const MonthlyFrequencyMessage = ({ children }) => (
  <FormattedMessage
    id="messages.monthlyFrequency"
    defaultMessage="Monthly. Sent the first of each month at 9:00am UTC.">
    {children}
  </FormattedMessage>
)

export const OnDemandFrequencyMessage = ({ children }) => (
  <FormattedMessage id="messages.onDemandFrequency" defaultMessage="Once. Sent as soon as the report is generated.">
    {children}
  </FormattedMessage>
)

export const AtAGlanceReportMessage = ({ children }) => (
  <FormattedMessage id="messages.atAGlanceReport" defaultMessage="At A Glance">
    {children}
  </FormattedMessage>
)

export const TitlePerformanceReportMessage = ({ children }) => (
  <FormattedMessage id="messages.titlePerformanceReport" defaultMessage="Title Performance Report">
    {children}
  </FormattedMessage>
)

export const TitleCirculationReportMessage = ({ children }) => (
  <FormattedMessage id="messages.titleCirculationReport" defaultMessage="Title Circulation Report">
    {children}
  </FormattedMessage>
)

export const SalesOverviewReportMessage = ({ children }) => (
  <FormattedMessage id="messages.salesOverviewReport" defaultMessage="Circulation and Sales Overview Report">
    {children}
  </FormattedMessage>
)

export const SalesDetailsReportMessage = ({ children }) => (
  <FormattedMessage id="messages.salesDetailsReport" defaultMessage="Circulation and Sales Details Report">
    {children}
  </FormattedMessage>
)

export const InventoryReportMessage = ({ children }) => (
  <FormattedMessage id="messages.inventoryReport" defaultMessage="Inventory">
    {children}
  </FormattedMessage>
)

export const RequiredMessage = ({ children }) => (
  <FormattedMessage id="messages.required" defaultMessage="(required)">
    {children}
  </FormattedMessage>
)

export const DateAddedMessage = ({ children }) => (
  <FormattedMessage id="messages.dateAdded" defaultMessage="Date Added">
    {children}
  </FormattedMessage>
)

export const AudiobookMessage = ({ children }) => (
  <FormattedMessage id="messages.audiobook" defaultMessage="Audiobook">
    {children}
  </FormattedMessage>
)

export const ComicMessage = ({ children }) => (
  <FormattedMessage id="messages.comic" defaultMessage="Comic">
    {children}
  </FormattedMessage>
)

export const EbookMessage = ({ children }) => (
  <FormattedMessage id="messages.ebook" defaultMessage="eBook">
    {children}
  </FormattedMessage>
)

export const MusicMessage = ({ children }) => (
  <FormattedMessage id="messages.music" defaultMessage="Music">
    {children}
  </FormattedMessage>
)

export const MovieMessage = ({ children }) => (
  <FormattedMessage id="messages.movie" defaultMessage="Movie">
    {children}
  </FormattedMessage>
)

export const TelevisionMessage = ({ children }) => (
  <FormattedMessage id="messages.television" defaultMessage="Television">
    {children}
  </FormattedMessage>
)

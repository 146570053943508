import { useEffect, useRef, useState } from 'react'

import { METHOD_STORAGE_KEY } from '../../store/constants'

export function useStoredMethod(method) {
  const initialModel = window.localStorage.getItem(METHOD_STORAGE_KEY) || method

  const [state, setState] = useState(initialModel)

  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    window.localStorage.setItem(METHOD_STORAGE_KEY, initialModel)
    return () => (isMounted.current = false)
  }, [initialModel])

  const safeSetState = nextMethod => {
    if (isMounted.current) {
      window.localStorage.setItem(METHOD_STORAGE_KEY, nextMethod)
      setState(nextMethod)
    }
  }

  return [state, safeSetState]
}

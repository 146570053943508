import React, { Fragment, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { AppState } from '../store/GlobalState'
import { LayoutFooter, LayoutFooterInner, P } from 'ui'

import packageSettings from '../../package.json'

export const Footer = () => {
  const { token } = useContext(AppState)

  return (
    <LayoutFooter>
      <LayoutFooterInner>
        <P fontSize={0}>
          <FormattedMessage
            id="footer.copywright"
            defaultMessage="Copyright © {year} hoopla Digital. All rights reserved"
            values={{ year: new Date().getFullYear() }}
          />
        </P>

        <P fontSize={0}>
          <FormattedMessage
            id="footer.version"
            defaultMessage="{helpLink}{tocLink}"
            values={{
              helpLink: (
                <Fragment>
                  {token && (
                    <Fragment>
                      <Link to="/dashboard/help">
                        <FormattedMessage id="footer.helpLinkText" defaultMessage="Help" />
                      </Link>
                      {' | '}
                    </Fragment>
                  )}
                </Fragment>
              ),
              tocLink: (
                <a
                  href="https://s3.amazonaws.com/com.hoopladigital.web/vendor/hoopla-vendor-terms-conditions.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage id="footer.tocLinkText" defaultMessage="Terms and Conditions" />
                </a>
              ),
            }}
          />
        </P>

        <P fontSize={0}>
          <FormattedMessage
            id="footer.browserSupport"
            defaultMessage="This site requires an HTML5-compliant browser."
          />
        </P>

        <P fontSize={0}>Version {packageSettings.version}</P>
      </LayoutFooterInner>
    </LayoutFooter>
  )
}

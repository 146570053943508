import React, { useRef, useEffect } from 'react'

import { NOTIFICATION_TYPES } from '../../store/constants'
import { NotificationError, NotificationInfo, NotificationSuccess, NotificationWarning } from '../../ui'
import { useSafeSetState } from './useSafeSetState'

// default / overridable message type
const DEFAULT_MESSAGE_TYPE = NOTIFICATION_TYPES.info

// default / overridable message description
const DEFAULT_MESSAGE_DESCRIPTION = ''

/**
 * Get the message component to show on screen
 *
 * @param type {string} - one of NOTIFICATION_TYPES
 * @param description {string} - the text of the notification
 * @returns {null|object} - the component to show on screen
 */
const getMessageComponent = (type, description) => {
  if (description) {
    switch (type) {
      case NOTIFICATION_TYPES.success:
        return <NotificationSuccess>{description}</NotificationSuccess>
      case NOTIFICATION_TYPES.warning:
        return <NotificationWarning>{description}</NotificationWarning>
      case NOTIFICATION_TYPES.error:
        return <NotificationError>{description}</NotificationError>
      case NOTIFICATION_TYPES.info:
        return <NotificationInfo>{description}</NotificationInfo>
      default:
        return null
    }
  } else {
    return null
  }
}

/**
 * Create self-destructive messages
 *
 * @param defaultType {string} - one of NOTIFICATION_TYPES
 * @param defaultDescription {string} - the text of the notification
 * @returns {*[]}
 */
export function useSelfDestructiveMessage(
  defaultType = DEFAULT_MESSAGE_TYPE,
  defaultDescription = DEFAULT_MESSAGE_DESCRIPTION,
) {
  // set initial message
  const initialMessageComponent = getMessageComponent(defaultType, defaultDescription)
  const [MessageComponent, setMessageComponent] = useSafeSetState(initialMessageComponent)

  // make a timer ref
  const timerId = useRef(undefined)

  // timer function to auto-destroy messages
  const summonThanos = () => {
    timerId.current = setTimeout(() => {
      setMessageComponent(initialMessageComponent)
      timerId.current = undefined
    }, 5000)
    return timerId.current
  }

  // show a new message and start the timer
  const showNewMessageComponent = (type, description) => {
    if (!type || !type instanceof String || !description || !description instanceof String) {
      throw new Error('Message type {string} and description {string} are needed to show messages.')
    }

    if (Object.values(NOTIFICATION_TYPES).indexOf(type) < 0) {
      throw new Error(`
        Message type must be one of ${Object.values(NOTIFICATION_TYPES).join(', ')}, but you provided ${type}.
      `)
    }

    // get the next message component
    const nextMessage = getMessageComponent(type, description)

    // set the next message component
    setMessageComponent(nextMessage)

    // start the destruction timer
    summonThanos()
  }

  useEffect(() => {
    return () => {
      // when this component unloads, clear out the timer if needed
      if (timerId.current) {
        clearTimeout(timerId.current)
      }
    }
  }, [])

  return [MessageComponent, showNewMessageComponent]
}

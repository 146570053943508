import React, { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { AppState } from 'store/GlobalState'
import { DashboardTemplate } from 'components/common/DashboardTemplate'
import { CustomizedTemplate } from '../components/common/CustomizedTemplate'

export const DashboardPaths = () => {
  const { token } = useContext(AppState)
  const location = useLocation()

  if (!token || !token.value) {
    return <Navigate to="/" replace={true} />
    return null
  } else if (location.pathname.match(/dashboard\/products/)) {
    return (
      <CustomizedTemplate>
        <Outlet />
      </CustomizedTemplate>
    )
  } else {
    return (
      <DashboardTemplate>
        <Outlet />
      </DashboardTemplate>
    )
  }
}

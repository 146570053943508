import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import 'react-dates/lib/css/_datepicker.css'


import { useSafeSetState } from '../../components/hooks'

import { formElementBase, themeValue } from '../../ui'

const DatePickerWrapper = styled.div`
  input {
    ${formElementBase()};
    border: 1px solid ${themeValue('colors.secondary.highlight')};
    width: 100%;
  }

  .SingleDatePickerInput,
  .DateInput {
    background-color: transparent;
  }

  .SingleDatePicker_picker {
    z-index: 10;
  }

  .SingleDatePicker__picker,
  .DayPicker,
  .CalendarMonth,
  .CalendarMonthGrid,
  .CalendarDay__default {
    background-color: ${themeValue('colors.background')};
  }

  .CalendarDay__default {
    border-color: ${themeValue('colors.secondary.pale')};
  }

  .CalendarDay__default,
  .CalendarMonth_caption,
  .DayPicker_weekHeader {
    color: ${themeValue('colors.foreground')};
  }

  .CalendarDay__default:hover {
    background-color: ${themeValue('colors.backgroundDark')};
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${themeValue('colors.primary.base')};
    border: 1px double ${themeValue('colors.primary.base')};
    color: ${themeValue('colors.always.white')};
  }

  .CalendarDay__blocked_out_of_range {
    background-color: ${themeValue('colors.backgroundDark')};
    color: ${themeValue('colors.secondary.base')};
  }

  .DayPickerKeyboardShortcuts_panel {
    background-color: ${themeValue('colors.backgroundDark')};
    color: ${themeValue('colors.foreground')};
    border-color: ${themeValue('colors.foreground')};
  }

  .DayPickerNavigation_button__default {
    background-color: ${themeValue('colors.background')};
    border-color: ${themeValue('colors.secondary.highlight')};

    &:hover,
    &:active {
      background-color: ${themeValue('colors.secondary.highlight')};
    }

    svg {
      fill: ${themeValue('colors.foreground')};
    }
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    &::before {
      border-right-color: ${themeValue('colors.accent.base')};
    }

    &:active,
    &:hover {
      &::before {
        border-right-color: ${themeValue('colors.accent.interaction')};
      }
    }
  }
`
DatePickerWrapper.displayName = 'DatePickerWrapper'

export const DatePicker = ({
  startDate,
  endDate,
  startDateId,
  endDateId,
  onStartDateChange,
  onEndDateChange,
  limitedDateRange,
  ...rest
}) => {
  const [isStartFocused, setStartFocused] = useSafeSetState(false)
  const [isEndFocused, setEndFocused] = useSafeSetState(false)

  const validateDate = (date, current, callback) => {
    if (moment(date).isValid()) {
      return callback(moment(date).format('YYYY-MM-DD'))
    } else {
      return callback(moment(current).format('YYYY-MM-DD'))
    }
  }

  return (
    <DatePickerWrapper>
      <SingleDatePicker
        {...rest}
        noBorder
        small
        isOutsideRange={date => {
          return moment(endDate)
            .endOf('day')
            .isBefore(date)
        }}
        enableOutsideDays={true}
        date={moment(startDate)}
        id={startDateId}
        onDateChange={newDate => {
          validateDate(newDate, startDate, onStartDateChange)

          const dateLimit = moment(newDate)
            .add(3, 'month')
            .subtract(1, 'day')
            .format('YYYY-MM-DD')

          if (
            limitedDateRange &&
            moment(endDate)
              .startOf('day')
              .isAfter(dateLimit)
          ) {
            onEndDateChange(dateLimit)
          }
        }}
        focused={isStartFocused}
        onFocusChange={({ focused }) => setStartFocused(focused)}
        displayFormat="L"
        numberOfMonths={1}
      />
      &ndash;
      <SingleDatePicker
        {...rest}
        noBorder
        small
        numberOfMonths={1}
        isOutsideRange={date => {
          if (limitedDateRange) {
            return !moment(date).isBetween(moment(startDate), moment(startDate).add(3, 'month'))
          } else {
            return moment(startDate)
              .startOf('day')
              .isAfter(date)
          }
        }}
        date={moment(endDate)}
        id={endDateId}
        onDateChange={newDate => validateDate(newDate, startDate, onEndDateChange)}
        focused={isEndFocused}
        onFocusChange={({ focused }) => setEndFocused(focused)}
        displayFormat="L"
      />
    </DatePickerWrapper>
  )
}

DatePicker.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  startDateId: PropTypes.string.isRequired,
  endDateId: PropTypes.string.isRequired,
  limitedDateRange: PropTypes.bool.isRequired,
}

DatePicker.defaultProps = {
  limitedDateRange: false,
}

import { useEffect, useReducer, useRef } from 'react'

// Emulates basic setState functionality from traditional React classes.
export function useSimpleReducer(initialState) {
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), initialState)

  // track if this component is mounted
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  const safeSetState = (...args) => isMounted.current && setState(...args)

  return [state, safeSetState]
}

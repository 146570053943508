import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { themeValue } from '../theme'
import { Card } from './Card'

//
// Code was taken and modified from styled-css-grid
// See https://styled-css-grid.js.org/
//
//

const autoRows = ({ minRowHeight = '20px' }) => `minmax(${minRowHeight}, auto)`

const frGetter = value => (typeof value === 'number' ? `repeat(${value}, 1fr)` : value)

const flow = ({ flow = 'row' }) => flow

const formatAreas = areas => areas.map(area => `"${area}"`).join(' ')

export const CardList = styled.div`
  display: grid;

  height: ${({ height = 'auto' }) => height};

  grid-auto-flow: ${flow};

  grid-auto-rows: ${autoRows};

  ${({ rows }) => rows && `grid-template-rows: ${frGetter(rows)}`};

  grid-template-columns: ${({ columns = 12 }) => frGetter(columns)};

  grid-gap: ${themeValue('space.base')};

  ${({ columnGap }) => columnGap && `column-gap: ${columnGap}`};

  ${({ rowGap }) => rowGap && `row-gap: ${rowGap}`};

  ${({ areas }) => areas && `grid-template-areas: ${formatAreas(areas)}`};

  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};

  ${({ alignContent }) => alignContent && `align-content: ${alignContent}`};

  ${props =>
    props.condensed &&
    css`
      ${Card} {
        padding: 0 !important;
      }
    `}
`

CardList.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gap: PropTypes.string,
  columnGap: PropTypes.string,
  rowGap: PropTypes.string,
  height: PropTypes.string,
  minRowHeight: PropTypes.string,
  flow: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  areas: PropTypes.arrayOf(PropTypes.string),
  justifyContent: PropTypes.string,
  alignContent: PropTypes.string,
  condensed: PropTypes.bool,
}

CardList.defaultProps = {
  condensed: false,
}

CardList.displayName = 'CardList'

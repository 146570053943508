import React, { useEffect } from 'react'
import jwt_decode from 'jwt-decode'

import { GET_KINDS, GET_VENDOR, GET_VENDOR_PUBLISHERS } from './queries'
import Storage from './Storage'
import {
  CIRCULATION_METHOD_OPTIONS,
  CIRCULATIONS_HOLDS_FILTERS_STORAGE_KEY,
  CIRCULATIONS_OVERVIEW_FILTERS_STORAGE_KEY,
  GLANCE_FILTERS_STORAGE_KEY,
  INVENTORY_FILTERS_STORAGE_KEY,
  SETTINGS_STORAGE_KEY,
  TITLE_CIRCULATIONS_FILTERS_STORAGE_KEY,
  TITLE_PERFORMANCE_FILTERS_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
} from './constants'
import { client } from './api'
import { useSafeSetState, useStoredMethod, useStoredReducer } from '../components/hooks'

/**
 * Check several layers of overrides to see if
 * a directive should be used
 *
 * @param primaryTest {boolean} - an initial condition that can short circuit this test
 * @param vendorSetting {boolean} - a vendor setting (e.g. hideFlexCircs) provided from backend
 * @param isUserAdmin {boolean} - is the current user an hoopla admin user?
 * @param isMasqueradingVendor - is the current hoopla admin user masquerading a vendor user?
 * @returns {*|boolean}
 */
const shouldComply = (primaryTest, vendorSetting, isUserAdmin, isMasqueradingVendor) => {
  return primaryTest && ((!isUserAdmin && vendorSetting) || (isUserAdmin && vendorSetting && isMasqueradingVendor))
}

/**
 * Decode a JWT, which provides the current user
 * and vendorId
 *
 * @param token {string} - current JWT for auth'd user
 * @returns {{vendor: object, user: object}}
 */
const decodeToken = (token) => {
  return jwt_decode(token)
}

// interface to app storage
const storage = new Storage()

// expiration time of stored things
const expirationTime = 60 * 60 * 8 * 1000

// default circulation method used in app
const DEFAULT_METHOD = CIRCULATION_METHOD_OPTIONS.all.id

// default app settings. these are ephemeral
// settings that could change between sessions
// or even within a session.
const defaultSettings = {
  isHidingMargin: false,
  isHidingInstantLibraries: false,
  isHidingFlexLibraries: false,
  isHidingFlexCirculations: false,
  isHidingAllRevenue: false,
  isHidingFlexRevenue: false,
  isHidingInstantRevenue: false,
  isHidingHolds: false,
  isMasqueradingVendor: false,
  isModelSwitchingEnabled: true,
  shouldHideFlexCirculations: false,
  shouldHideFlexRevenue: false,
  shouldHideInstantRevenue: false,
  kinds: [],
  vendor: null,
  user: null,
}

const getInitialMethod = (vendorProductTypes, storedMethod) => {
  if (!vendorProductTypes.length) {
    // not sure if this will ever happen, but
    // default to showing ALL
    return CIRCULATION_METHOD_OPTIONS.all.id
  } else if (vendorProductTypes.length === 1) {
    // if the vendor has only one product type,
    // the model switcher will not show and they
    // will see data related to their one product type
    return CIRCULATION_METHOD_OPTIONS[vendorProductTypes[0].toLowerCase()].id
  } else {
    // in this case, the vendor has more than
    // one product type. show the stored method.
    return storedMethod
  }
}

export const AppState = React.createContext(null)

export const AppStateProvider = ({ children }) => {
  const [isBooting, setBooting] = useSafeSetState(false)
  const [vendorPublishers, setVendorPublishers] = useSafeSetState([])
  const [method, setMethod] = useStoredMethod(DEFAULT_METHOD)
  const [token, setToken] = useStoredReducer(null, TOKEN_STORAGE_KEY, expirationTime)
  const [settings, setSettings] = useStoredReducer(defaultSettings, SETTINGS_STORAGE_KEY, expirationTime)

  useEffect(() => {
    const init = async () => {
      const { isMasqueradingVendor } = settings

      // set the booting flag, so the UI can show loading
      setBooting(true)

      const kinds = await loadKinds()
      setSettings({ kinds })

      if (token && token.value) {
        // decode the JWT
        const decoded = decodeToken(token.value)

        // fetch the current vendor's publishers
        const vendorPublishers = await loadVendorPublishers(decoded.vendorId)
        setVendorPublishers(vendorPublishers)

        // get selected vendor
        const refreshedVendor = await loadVendor(decoded.vendorId)

        // get the displayed purchase method
        const initialMethod = getInitialMethod(refreshedVendor.productTypes, method)

        // set the purchase method / model
        setMethod(initialMethod)

        // update all the local settings
        updateLocalSettings(initialMethod, refreshedVendor, decoded, isMasqueradingVendor)
      }

      // unset the booting flag
      setBooting(false)
    }

    init()

    return undefined
  }, [])

  /**
   * Change the displayed pricing model in the UI
   *
   * @param newMethod {string} - pricing method INSTANT|FLEX|ALL
   */
  const changeMethod = async (newMethod) => {
    const { vendor, user, isMasqueradingVendor } = settings

    // set the method in app state
    setMethod(newMethod)

    // update settings
    updateLocalSettings(newMethod, vendor, user, isMasqueradingVendor)

    // dump apollo cache to force re-fetching everything
    await client.resetStore()
  }

  /**
   * Update state settings, which are dependent on the user,
   * vendor, method, and if an admin user is masquerading a vendor user
   *
   * @param currentMethod {string} - pricing method PPU|EST|ALL
   * @param forVendor {object} - the vendor object to set settings for
   * @param forUser {object} - the user object to set settings for
   * @param shouldMasqueradeVendor {boolean} - is the provided forUser masquerading a vendor user?
   */
  const updateLocalSettings = (currentMethod, forVendor, forUser, shouldMasqueradeVendor) => {
    // calculate hidden data flags
    setSettings({
      vendor: forVendor,
      user: forUser,
      isHidingMargin: shouldComply(true, forVendor.hideMargin, forUser.isAdmin, shouldMasqueradeVendor),
      isHidingInstantLibraries: shouldComply(
        CIRCULATION_METHOD_OPTIONS.instant.id === currentMethod,
        forVendor.hideInstantLibraryNames,
        forUser.isAdmin,
        shouldMasqueradeVendor,
      ),
      isHidingFlexLibraries: shouldComply(
        CIRCULATION_METHOD_OPTIONS.flex.id === currentMethod,
        forVendor.hideFlexLibraryNames,
        forUser.isAdmin,
        shouldMasqueradeVendor,
      ),
      isHidingFlexCirculations: shouldComply(
        CIRCULATION_METHOD_OPTIONS.flex.id === currentMethod,
        forVendor.hideFlexCircs,
        forUser.isAdmin,
        shouldMasqueradeVendor,
      ),
      isHidingAllRevenue: shouldComply(
        CIRCULATION_METHOD_OPTIONS.all.id === currentMethod,
        forVendor.hideFlexRevenue && forVendor.hideInstantRevenue,
        forUser.isAdmin,
        shouldMasqueradeVendor,
      ),
      isHidingFlexRevenue: shouldComply(
        CIRCULATION_METHOD_OPTIONS.flex.id === currentMethod,
        forVendor.hideFlexRevenue,
        forUser.isAdmin,
        shouldMasqueradeVendor,
      ),
      isHidingInstantRevenue: shouldComply(
        CIRCULATION_METHOD_OPTIONS.instant.id === currentMethod,
        forVendor.hideInstantRevenue,
        forUser.isAdmin,
        shouldMasqueradeVendor,
      ),
      isHidingHolds: shouldComply(true, forVendor.hideHolds, forUser.isAdmin, shouldMasqueradeVendor),
      isMasqueradingVendor: shouldMasqueradeVendor,
      shouldHideFlexCirculations: shouldComply(true, forVendor.hideFlexCircs, forUser.isAdmin, shouldMasqueradeVendor),
      shouldHideFlexRevenue: shouldComply(true, forVendor.hideFlexRevenue, forUser.isAdmin, shouldMasqueradeVendor),
      shouldHideInstantRevenue: shouldComply(
        true,
        forVendor.hideInstantRevenue,
        forUser.isAdmin,
        shouldMasqueradeVendor,
      ),
    })
  }

  /**
   * Toggle the masquerade vendor settings in
   * the app state
   *
   * @param should {boolean} - should the admin user masquerade a vendor user?
   */
  const masqueradeVendor = async (should) => {
    const { vendor, user } = settings

    // if we don't have a current user or vendor, dump
    // the app state
    if (!user || !vendor) return logout()

    // masquerading vendors is allowed for hoopla admins only!
    if (!user.isAdmin) throw new Error('Only admin users can do this')

    // update settings
    updateLocalSettings(method, vendor, user, should)

    // dump apollo cache to force re-fetching everything
    await client.reFetchObservableQueries()
  }

  /**
   * Complete the authentication process for a provided JWT token
   *
   * @param token {string} - current JWT for auth'd user
   */
  const completeLogin = async (token) => {
    if (!token) throw new Error('No token found for current user')

    // put the app into booting mode while we do our thing
    await setBooting(true)

    // store the token
    setToken({ value: token })

    // decode the JWT
    const decoded = decodeToken(token)

    // clear out any stored report filters
    // (these can be different for each vendor)
    clearStoredFilters()

    // get selected vendor
    const vendor = await loadVendor(decoded.vendorId)

    // get the displayed purchase method
    const initialMethod = getInitialMethod(vendor.productTypes, method)

    // set the purchase method / model
    setMethod(initialMethod)

    // update all the local settings
    updateLocalSettings(initialMethod, vendor, decoded, false)

    // fetch the current vendor's publishers
    const vendorPublishers = await loadVendorPublishers(decoded.vendorId)
    setVendorPublishers(vendorPublishers)

    // unset booting mode
    await setBooting(false)
  }

  /**
   * Loads kinds
   *
   * @returns {Promise<void>}
   */
  const loadKinds = () => {
    return client.query({ query: GET_KINDS }).then((response) => response.data.kinds)
  }

  /**
   * Loads publishers for the current vendor
   *
   * @returns {Promise<void>}
   */
  const loadVendorPublishers = (vendorId) => {
    return client
      .query({ query: GET_VENDOR_PUBLISHERS, variables: { vendorId } })
      .then((response) => response.data.vendorPublishers)
  }

  /**
   * Loads vendor
   *
   * @returns {Promise<void>}
   */
  const loadVendor = (vendorId) => {
    return client.query({ query: GET_VENDOR, variables: { id: vendorId } }).then((response) => response.data.vendor)
  }

  /**
   * Complete logout process
   */
  const logout = async () => {
    // dump the token
    setToken(null)

    // change to default model
    setMethod(DEFAULT_METHOD)

    // reset vendor publishers
    setVendorPublishers([])

    // reload kinds
    const kinds = await loadKinds()

    // use default settings
    setSettings({
      ...defaultSettings,
      kinds,
    })

    // clear out any stored report filters
    // (these can be different for each vendor)
    clearStoredFilters()

    // cancel any running queies
    client.stop()

    // dump apollo cache to force re-fetching everything
    client.resetStore()
  }

  const clearStoredFilters = () => {
    storage.remove(CIRCULATIONS_OVERVIEW_FILTERS_STORAGE_KEY)
    storage.remove(CIRCULATIONS_HOLDS_FILTERS_STORAGE_KEY)
    storage.remove(GLANCE_FILTERS_STORAGE_KEY)
    storage.remove(TITLE_PERFORMANCE_FILTERS_STORAGE_KEY)
    storage.remove(TITLE_CIRCULATIONS_FILTERS_STORAGE_KEY)
    storage.remove(INVENTORY_FILTERS_STORAGE_KEY)
  }

  return (
    <AppState.Provider
      value={{
        isBooting,
        method,
        changeMethod,
        settings,
        setSettings,
        completeLogin,
        logout,
        clearStoredFilters,
        masqueradeVendor,
        vendorPublishers,
        token,
      }}
    >
      {children}
    </AppState.Provider>
  )
}

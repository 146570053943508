import styled from 'styled-components'

import { themeValue } from '../theme'
import { notificationBase } from '../utils'
import { P } from '../Typography'

export const NotificationError = styled(P)`
  ${notificationBase()};
  background-color: ${themeValue('colors.error.base')};
  border-color: ${themeValue('colors.error.interaction')};
`
NotificationError.displayName = 'NotificationError'

export const NotificationSuccess = styled(P)`
  ${notificationBase()};
  background-color: ${themeValue('colors.success.base')};
  border-color: ${themeValue('colors.success.interaction')};
`
NotificationSuccess.displayName = 'NotificationSuccess'

export const NotificationInfo = styled(P)`
  ${notificationBase()};
  background-color: ${themeValue('colors.secondary.interaction')};
  border-color: ${themeValue('colors.secondary.intense')};
`
NotificationInfo.displayName = 'NotificationInfo'

export const NotificationWarning = styled(P)`
  ${notificationBase()};
  background-color: ${themeValue('colors.accent.base')};
  border-color: ${themeValue('colors.accent.interaction')};
`
NotificationWarning.displayName = 'NotificationWarning'

import React from 'react'
import styled from 'styled-components'

import { space } from '../utils'

export const HeroImageBackground = styled.div`
  position: absolute;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-attachment: fixed;
  backface-visibility: hidden;
  background-image: url(${props => props.image});
`
HeroImageBackground.displayName = 'HeroImageBackground'

export const HeroImageOverlay = styled.span`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
`
HeroImageOverlay.displayName = 'HeroImageOverlay'

const HeroImageWrapper = styled.div`
  position: relative;
  min-height: ${space(36)};
  height: 80vh;
  overflow: hidden;
`
HeroImageWrapper.displayName = 'HeroImageWrapper'

export const HeroImage = ({ children, image, ...rest }) => (
  <HeroImageWrapper {...rest}>
    <HeroImageBackground image={image} />
    <HeroImageOverlay />
    {children}
  </HeroImageWrapper>
)

import styled, { css } from 'styled-components'

import { mediaQuery, themeValue } from '../theme'
import { space } from '../utils'
import { H2 } from '../Typography'

export const Container = styled.section`
  position: relative;

  ${props =>
    !props.condensed &&
    css`
      & + & {
        margin-top: ${space(5)};
      }
    `};

  ${props =>
    props.condensed &&
    css`
      & + & {
        margin-top: ${themeValue('space.base')};
      }
    `};

  > *:nth-child(n + 2) {
    margin-top: ${themeValue('space.base')};
  }
`
Container.displayName = 'Container'

export const ContainerHeader = styled.header`
  border-bottom: 1px solid ${themeValue('colors.secondary.pale')};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: ${themeValue('space.tiny')};
`
ContainerHeader.displayName = 'ContainerHeader'

export const ContainerTitle = styled(H2)`
  margin: 0;
  line-height: 1;
`
ContainerTitle.displayName = 'ContainerTitle'

export const ContainerActions = styled.div`
  display: flex;

  & > *:nth-child(n + 2) {
    margin-left: ${themeValue('space.tiny')};
  }

  ${mediaQuery.medium`
    align-items: flex-end;
    flex-direction: row;
  `}
`
ContainerActions.displayName = 'ContainerActions'

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Refresh } from '@styled-icons/material/Refresh'
import { ChevronRight } from '@styled-icons/material/ChevronRight'
import { SortDown } from '@styled-icons/fa-solid/SortDown'
import { Sort } from '@styled-icons/fa-solid/Sort'
import { Download } from '@styled-icons/boxicons-regular/Download'
import { Email } from '@styled-icons/material/Email'
import { PersonOutline } from '@styled-icons/material/PersonOutline'
import { Menu } from '@styled-icons/material/Menu'
import { Settings } from '@styled-icons/material/Settings'
import { Close } from '@styled-icons/material/Close'
import { Calendar } from '@styled-icons/octicons/Calendar'
import { Lock } from '@styled-icons/material/Lock'
import { ChartBar } from '@styled-icons/fa-regular/ChartBar'
import { FilePdf } from '@styled-icons/fa-solid/FilePdf'
import { HelpCircle } from '@styled-icons/boxicons-regular/HelpCircle'
import { LogOut } from '@styled-icons/boxicons-regular/LogOut'
import { RightLeft } from '@styled-icons/fa-solid/RightLeft'
import { Printer } from '@styled-icons/boxicons-regular/Printer'
import { Clock } from '@styled-icons/fa-regular/Clock'
import { FileEarmarkSpreadsheet as Spreadsheet } from '@styled-icons/bootstrap/FileEarmarkSpreadsheet'

import { themeValue } from '../theme'

const iconType = {
  calendar: Calendar,
  change: RightLeft,
  chart: ChartBar,
  chevron: ChevronRight,
  clock: Clock,
  close: Close,
  download: Download,
  email: Email,
  help: HelpCircle,
  lock: Lock,
  logout: LogOut,
  menu: Menu,
  person: PersonOutline,
  pdf: FilePdf,
  printer: Printer,
  refresh: Refresh,
  setting: Settings,
  sort: SortDown,
  sortInactive: Sort,
  spreadsheet: Spreadsheet,
}

export const Icon = ({ className, color, hoverColor, onClick, rotate, size, type, style }) => {
  if (!iconType[type]) {
    throw new Error(`Pick a valid <Icon /> type. "${type}" is not valid.`)
  }

  const IconComponent = styled(iconType[type])`
    color: ${themeValue(color)};
    transition: inherit;

    ${hoverColor &&
      css`
        &:hover {
          color: ${themeValue(hoverColor)};
        }
      `}

    /* If there is an onClick handler, make the icon look clickable */
    ${onClick &&
      css`
        cursor: pointer;
      `}

    /* Rotate the icon if needed */
    ${rotate &&
      css`
        transform: rotate(${rotate}deg);
      `}
  `

  return <IconComponent size={size} className={className} onClick={onClick} style={style} />
}

Icon.propTypes = {
  type: PropTypes.oneOf([
    'calendar',
    'change',
    'chart',
    'chevron',
    'close',
    'download',
    'help',
    'email',
    'lock',
    'logout',
    'menu',
    'person',
    'pdf',
    'printer',
    'refresh',
    'setting',
    'sort',
    'sortInactive',
    'spreadsheet',
  ]),
  rotate: PropTypes.number,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Icon.defaultProps = {
  color: 'colors.foreground',
  size: 24,
}

import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { useBreakpoints } from '../hooks'
import { themeValue, BareButton, Card, CardList, Container, H2, Icon, P } from '../../ui'
import { Portal } from '../common/Portal'
import { ContactModal } from '../common/ContactModal'

import TabletForVendor from './tablet-for-vendor.jpg'
import VendorPortal from './vendor-portal.png'

export const TopSection = () => {
  const columnCount = useBreakpoints([
    { width: 800, value: 2 },
    { width: 0, value: 1 },
  ])

  return (
    <Container>
      <CardList columns={columnCount}>
        <Card shadow={false} middle>
          <SectionHeader>
            <FormattedMessage id="homepage.section.top.title" defaultMessage="A new, sustainable channel" />
          </SectionHeader>
          <FormattedMessage
            id="homepage.section.top.content"
            defaultMessage="Hoopla digital is used by over a million public library patrons who love all forms of digital content. In fact, libraries are providing a significant market for publishers and content providers. With digital channels overtaking physical formats to become the dominant revenue stream, hoopla and the library form the ideal way to provide content exposure that's often not possible through more mainstream consumer services - a sustainable channel and service that's growing every day!"
          />
        </Card>

        <Card shadow={false} center>
          <img src={TabletForVendor} alt="hoopla works on tablets too" height={200} />
        </Card>
      </CardList>
    </Container>
  )
}

export const MiddleSection = () => {
  const count = useBreakpoints([
    { width: 800, value: 2 },
    { width: 0, value: 1 },
  ])

  return (
    <Container>
      <CardList columns={count}>
        <Card shadow={false} middle top={1} left={count}>
          <SectionHeader>
            <FormattedMessage id="homepage.section.middle.title" defaultMessage="Innovative model" />
          </SectionHeader>
          <FormattedMessage
            id="homepage.section.middle.content"
            defaultMessage="We've pioneered the per-circulation transaction model. Instead of buying one or more electronic copies of your titles, participating libraries pay a fee each and every time one of your titles is lent to a library patron. Patrons love it because there are no holds or artificial restrictions hindering their access to titles, and ensures content providers receive a constant revenue stream for as long as their titles are circulating. Plus, hoopla removes the barrier of entry for libraries, allowing them to offer your full catalog of titles."
          />
        </Card>

        <Card shadow={false} center top={count === 2 ? 1 : 2}>
          <img src={VendorPortal} alt="a view of the vendor portal" height={200} />
        </Card>
      </CardList>
    </Container>
  )
}

export const LowerSection = () => {
  const columnCount = useBreakpoints([
    { width: 800, value: 3 },
    { width: 0, value: 1 },
  ])

  return (
    <Container>
      <CardList columns={columnCount}>
        <Card shadow={false}>
          <Icon type="calendar" color="colors.primary.base" size={50} style={{ margin: '0 auto' }} />

          <SmallSectionHeader fontSize={2} align="center">
            <FormattedMessage id="homepage.section.lower.first.title" defaultMessage="Campaign opportunities" />
          </SmallSectionHeader>

          <FormattedMessage
            id="homepage.section.lower.first.content"
            defaultMessage="Planning ahead is a key strategy for sharing your content. The hoopla editorial calendar gives you a quick and effective way to identify holidays, upcoming events, and potential seasonal opportunites."
          />

          <LearnMore>
            <Portal closeOnEsc>
              <Portal.Toggle>
                {({ openPortal }) => (
                  <HoverButton onClick={openPortal}>
                    <FormattedMessage id="homepage.section.lower.first.learnMore" defaultMessage="Learn More" />
                    <Icon type="chevron" size={18} color="colors.primary.base" />
                  </HoverButton>
                )}
              </Portal.Toggle>

              <Portal.Content>
                <ContactModal />
              </Portal.Content>
            </Portal>
          </LearnMore>
        </Card>

        <Card shadow={false}>
          <Icon type="chart" color="colors.primary.base" size={50} style={{ margin: '0 auto' }} />

          <SmallSectionHeader fontSize={2} align="center">
            <FormattedMessage id="homepage.section.lower.second.title" defaultMessage="Transaction transparency" />
          </SmallSectionHeader>

          <FormattedMessage
            id="homepage.section.lower.second.content"
            defaultMessage="Our portal for content providers has robust reports and tools that detail circulations and revenue in real time, providing you timely performance insights."
          />

          <LearnMore>
            <Portal closeOnEsc>
              <Portal.Toggle>
                {({ openPortal }) => (
                  <HoverButton onClick={openPortal}>
                    <FormattedMessage id="homepage.section.lower.second.learnMore" defaultMessage="Learn More" />
                    <Icon type="chevron" size={18} color="colors.primary.base" />
                  </HoverButton>
                )}
              </Portal.Toggle>

              <Portal.Content>
                <ContactModal />
              </Portal.Content>
            </Portal>
          </LearnMore>
        </Card>

        <Card shadow={false}>
          <Icon type="lock" color="colors.primary.base" size={50} style={{ margin: '0 auto' }} />

          <SmallSectionHeader fontSize={2} align="center">
            <FormattedMessage id="homepage.section.lower.third.title" defaultMessage="Content security" />
          </SmallSectionHeader>

          <FormattedMessage
            id="homepage.section.lower.third.content"
            defaultMessage="Hoopla uses robust industry standard content protection mechanisms which have undergone numerous security reviews by many of the largest content providers in the world. Your content is always safe in hoopla!"
          />

          <LearnMore>
            <Portal closeOnEsc>
              <Portal.Toggle>
                {({ openPortal }) => (
                  <HoverButton onClick={openPortal}>
                    <FormattedMessage id="homepage.section.lower.third.learnMore" defaultMessage="Learn More" />
                    <Icon type="chevron" size={18} color="colors.primary.base" />
                  </HoverButton>
                )}
              </Portal.Toggle>

              <Portal.Content>
                <ContactModal />
              </Portal.Content>
            </Portal>
          </LearnMore>
        </Card>
      </CardList>
    </Container>
  )
}

const SectionHeader = styled(H2)`
  margin-bottom: ${themeValue('space.base')};
`
SectionHeader.displayName = 'SectionHeader'

const SmallSectionHeader = styled(H2)`
  margin: ${themeValue('space.base')} 0;
`
SmallSectionHeader.displayName = 'SmallSectionHeader'

const LearnMore = styled(P).attrs({
  color: 'colors.primary.base',
})`
  margin: ${themeValue('space.base')} 0;
`
LearnMore.displayName = 'LearnMore'

const HoverButton = styled(BareButton)`
  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
`
HoverButton.displayName = 'HoverButton'

import React, { Suspense, useContext, lazy } from 'react'

import { ErrorBoundary } from '../components/common/ErrorBoundary'
import { AppState } from 'store/GlobalState'
import { Helmet } from 'components/common/Helmet'
import { Loading } from 'ui'
import { CIRCULATION_METHOD_OPTIONS } from '../store/constants'

export const Circulations = () => {
  const { method } = useContext(AppState)

  const CirculationsOverview = lazy(() => import('../components/circulationsOverview/CirculationsOverview'))
  const CirculationsDetail = lazy(() => import('../components/circulationsDetail/CirculationsDetail'))

  return (
    <ErrorBoundary>
      <Helmet title="Circulations and Sales" />

      <Suspense fallback={<Loading />}>
        <CirculationsOverview />
      </Suspense>

      {method === CIRCULATION_METHOD_OPTIONS.flex.id && (
        <Suspense fallback={<Loading />}>
          <CirculationsDetail />
        </Suspense>
      )}
    </ErrorBoundary>
  )
}

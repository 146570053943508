import { createGlobalStyle } from 'styled-components'
import { reset } from 'styled-reset'

import { themeValue, mediaQuery } from './theme'

//
// Global injected style
//
export const GlobalStyle = createGlobalStyle`
  ${reset}
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,700');
  html {
    font-size: calc(${themeValue('typography.rems')} / 16 * 100%);
    width: 100%;
    height: 100%;
    ${mediaQuery.huge`font-size: calc(${themeValue('typography.rems')} / 14 * 100%);`};
  }
  body {
    background-color: ${themeValue('colors.backgroundDark')};
    font-family: ${themeValue('typography.sans')};
    font-size: calc(${themeValue('typography.baseFontSize')} / ${themeValue('typography.rems')} * 1rem);
    line-height: ${themeValue('typography.baseLineHeight')};
    color: ${themeValue('colors.foreground')};
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    
    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      box-sizing: border-box;
    }
    
    a {
      color: ${themeValue('colors.primary.base')};
      
      &:hover,
      &:focus {
        color: ${themeValue('colors.primary.interaction')};
      }
    }
    
    @keyframes placeholders {
      0% {
        background-color: ${themeValue('colors.secondary.base')};
        fill: ${themeValue('colors.secondary.base')};
      }

      30% {
        background-color: ${themeValue('colors.secondary.highlight')};
        fill: ${themeValue('colors.secondary.highlight')};
      }

      75%,100% {
        background-color: ${themeValue('colors.secondary.base')};
        fill: ${themeValue('colors.secondary.base')};
      }
    }
  }
  @media print {
    body {
      font-size: 12px;
      font-family: sans-serif;
      color: black;
      background-color: white;
    }

    *,
    *:before,
    *:after {
        background: transparent !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    table {
      table-layout: fixed !important;
      width: 100%;
      max-width: 100%;
      overflow-wrap: break-word;
      page-break-inside: auto;
    }
    
    thead,
    tfoot {
      display: table-row-group;
    }
    
    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    
    td, th, caption {
      text-align: left;
      hyphens: auto;
      padding: 2px;
      page-break-inside: avoid;
      page-break-after: auto;
    }
    
    tr, td, th, thead, tfoot {
    page-break-inside: avoid !important;
}
  }
`

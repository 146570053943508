import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Mutation } from '@apollo/client/react/components'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { RESET_PASSWORD, LOGIN } from '../../store/queries'
import { makeValidator } from 'utils/form'

import {
  ButtonGroup,
  Form,
  FormError,
  FormGroup,
  FormRequiredNotice,
  NotificationError,
  NotificationSuccess,
  FormLabel,
  Input,
  Modal,
  PrimaryButton,
  SecondaryButton,
} from 'ui'
import { RequiredMessage } from './Messages'
import { AppState } from '../../store/GlobalState'

const constraints = {
  loginEmail: {
    presence: true,
    email: true,
  },
  newPassword: {
    presence: true,
  },
  newPasswordConfirm: {
    presence: true,
    equality: {
      attribute: 'newPassword',
      message: <FormattedMessage id="validator.resetPassword" defaultMessage="Passwords are not matching." />,
      comparator: (v1, v2) => {
        return v1 === v2
      },
    },
  },
}

export const ResetPassword = ({ closePortal, token }) => {
  const { completeLogin } = useContext(AppState)
  const navigate = useNavigate()
  return (
    <Modal
      closeModal={closePortal}
      title={<FormattedMessage id="modals.resetPassword.title" defaultMessage="Update your password" />}>
      <Mutation mutation={RESET_PASSWORD}>
        {(resetPassword, { client }) => (
          <Formik
            initialValues={{
              loginEmail: '',
              newPassword: '',
              newPasswordConfirm: '',
            }}
            onSubmit={async ({ loginEmail, newPassword, newPasswordConfirm }, { setStatus, setSubmitting }) => {
              if (newPassword !== newPasswordConfirm) {
                return setStatus({
                  failed: (
                    <FormattedMessage
                      id="modals.resetPassword.mismatch"
                      defaultMessage="Your password and confirmation must be matching."
                    />
                  ),
                })
              }

              try {
                await resetPassword({ variables: { email: loginEmail, token, newPassword } })

                setStatus({
                  success: (
                    <FormattedMessage
                      id="modals.resetPassword.success"
                      defaultMessage="Your password has now been reset."
                    />
                  ),
                })

                // try to login
                const user = await client.mutate({
                  mutation: LOGIN,
                  variables: { username: loginEmail, password: newPassword },
                })

                // finish login steps
                await completeLogin(user.data.login)

                // go to the dashboard
                navigate('/dashboard')
              } catch (ex) {
                setStatus({ failed: ex.message.replace(/GraphQL error: /, '') })
              } finally {
                setSubmitting(false)
              }
            }}
            validate={makeValidator(constraints)}>
            {({ dirty, errors, handleBlur, handleChange, handleSubmit, isSubmitting, status, touched, values }) => (
              <Form onSubmit={handleSubmit}>
                {status && status.failed && (
                  <FormGroup>
                    <NotificationError>{status.failed}</NotificationError>
                  </FormGroup>
                )}

                {status && status.success && (
                  <FormGroup>
                    <NotificationSuccess>{status.success}</NotificationSuccess>
                  </FormGroup>
                )}

                <FormGroup>
                  <FormLabel htmlFor="loginEmail">
                    <FormattedMessage id="modals.resetPassword.form.loginEmail.label" defaultMessage="Email" />
                    {!touched.loginEmail && values.loginEmail === '' && (
                      <FormRequiredNotice>
                        <RequiredMessage />
                      </FormRequiredNotice>
                    )}
                  </FormLabel>
                  <FormattedMessage
                    id="modals.resetPassword.form.loginEmail.placeholder"
                    defaultMessage="Enter your address">
                    {placeholder => (
                      <Input
                        id="loginEmail"
                        type="email"
                        required
                        placeholder={placeholder}
                        hasError={touched.loginEmail && errors.loginEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.loginEmail}
                      />
                    )}
                  </FormattedMessage>
                  {touched.loginEmail && errors.loginEmail && <FormError>{errors.loginEmail}</FormError>}
                </FormGroup>

                <FormGroup>
                  <FormLabel htmlFor="newPassword">
                    <FormattedMessage id="modals.resetPassword.form.password.label" defaultMessage="Password" />
                    {!touched.newPassword && values.newPassword === '' && (
                      <FormRequiredNotice>
                        <RequiredMessage />
                      </FormRequiredNotice>
                    )}
                  </FormLabel>
                  <FormattedMessage
                    id="modals.resetPassword.form.password.placeholder"
                    defaultMessage="Enter your new password">
                    {placeholder => (
                      <Input
                        id="newPassword"
                        type="password"
                        required
                        placeholder={placeholder}
                        hasError={touched.newPassword && errors.newPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                      />
                    )}
                  </FormattedMessage>
                  {touched.newPassword && errors.newPassword && <FormError>{errors.newPassword}</FormError>}
                </FormGroup>

                <FormGroup>
                  <FormLabel htmlFor="newPasswordConfirm">
                    <FormattedMessage
                      id="modals.resetPassword.form.passwordConfirm.label"
                      defaultMessage="Confirm your new password"
                    />
                    {!touched.newPasswordConfirm && values.newPasswordConfirm === '' && (
                      <FormRequiredNotice>
                        <RequiredMessage />
                      </FormRequiredNotice>
                    )}
                  </FormLabel>
                  <FormattedMessage
                    id="modals.resetPassword.form.passwordConfirm.placeholder"
                    defaultMessage="Enter your new password a second time">
                    {placeholder => (
                      <Input
                        id="newPasswordConfirm"
                        type="password"
                        required
                        placeholder={placeholder}
                        hasError={touched.newPasswordConfirm && errors.newPasswordConfirm}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPasswordConfirm}
                      />
                    )}
                  </FormattedMessage>
                  {touched.newPasswordConfirm && errors.newPasswordConfirm && (
                    <FormError>{errors.newPasswordConfirm}</FormError>
                  )}
                </FormGroup>

                <ButtonGroup align="flex-end">
                  <PrimaryButton disabled={!dirty || isSubmitting || Object.keys(errors).length} type="submit">
                    <FormattedMessage id="modals.resetPassword.form.button.submit" defaultMessage="Update" />
                  </PrimaryButton>
                  <SecondaryButton type="button" onClick={closePortal}>
                    <FormattedMessage id="modals.resetPassword.form.button.cancel" defaultMessage="Cancel" />
                  </SecondaryButton>
                </ButtonGroup>
              </Form>
            )}
          </Formik>
        )}
      </Mutation>
    </Modal>
  )
}

ResetPassword.propTypes = {
  token: PropTypes.string.isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

export class Overlay extends React.PureComponent {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    document.body.appendChild(this.el)
  }

  componentWillUnmount() {
    document.body.removeChild(this.el)
  }

  render() {
    if (!this.props.children) {
      return null
    }
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

Overlay.propTypes = {
  children: PropTypes.node,
}

import React, { useRef } from 'react'
import styled from 'styled-components'
import SlickCarousel from 'react-slick'
import PropTypes from 'prop-types'

import { themeValue, mediaQuery } from '../theme'
import { IconButton } from '../Button'
import { Icon } from '../Icon'

const CarouselContainer = styled.div`
  /* the width is smaller than 100% to make room for buttons on either side */
  width: calc(100% - ${themeValue('space.medium')} * 2);
  margin: 0 auto;

  /* the slick carousel items */
  ${mediaQuery.medium`
    .slick-slide {
      max-width: 230px;
    }
  `};

  .slick-slide > * {
    /* 2px padding to show the box shadow of each item */
    /* padding left is bigger for the space between items */
    padding: 2px 2px 2px ${themeValue('space.base')};
  }
`
CarouselContainer.displayName = 'CarouselContainer'

const CarouselButton = styled(IconButton).attrs(props => ({
  style: {
    right: props.right ? '-3.78rem' : 'initial',
    left: props.left ? '-3.78rem' : 'initial',
  },
}))`
  /* override slick carousel styles */
  width: auto;
  height: auto;
  border: 1px solid ${themeValue('colors.secondary.base')};
  background-color: ${themeValue('colors.background')};
  border-radius: 50%;
  transition: background 250ms ease, color 250ms ease;

  &::before {
    content: '';
    display: none;
  }

  &:hover {
    border-color: ${themeValue('colors.primary.interaction')};
    background-color: ${themeValue('colors.primary.base')};

    svg {
      color: ${themeValue('colors.always.white')};
    }
  }

  &.slick-disabled {
    background-color: ${themeValue('colors.secondary.base')};

    &:hover {
      border-color: ${themeValue('colors.secondary.base')};
      background-color: ${themeValue('colors.secondary.base')};

      svg {
        color: ${themeValue('colors.always.black')};
      }
    }
  }
`
CarouselButton.displayName = 'CarouselButton'

const getSlideCount = (totalNumberAvailable, maxVisibleAllowed) => {
  if (totalNumberAvailable >= maxVisibleAllowed) {
    return maxVisibleAllowed
  } else {
    return totalNumberAvailable
  }
}

export const Carousel = ({ children }) => {
  const container = useRef(null)

  const defaultSettings = {
    autoplay: false,
    infinite: false,
    speed: 500,
    slidesToScroll: getSlideCount(children.length, 6),
    slidesToShow: getSlideCount(children.length, 6),
    prevArrow: (
      <CarouselButton left>
        <Icon type="chevron" rotate={180} hoverColor="colors.always.white" />
      </CarouselButton>
    ),
    nextArrow: (
      <CarouselButton right>
        <Icon type="chevron" hoverColor="colors.always.white" />
      </CarouselButton>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: getSlideCount(children.length, 4),
          slidesToScroll: getSlideCount(children.length, 4),
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: getSlideCount(children.length, 2),
          slidesToScroll: getSlideCount(children.length, 2),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: getSlideCount(children.length, 1),
          slidesToScroll: getSlideCount(children.length, 1),
        },
      },
    ],
  }

  return (
    <CarouselContainer ref={container}>
      <SlickCarousel {...defaultSettings}>{children}</SlickCarousel>
    </CarouselContainer>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
}

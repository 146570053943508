import styled from 'styled-components'

import { themeValue } from '../theme'
import { Inset } from '../Inset'
import { P } from '../Typography'

export const LayoutFooter = styled.footer`
  background-color: ${themeValue('colors.backgroundDark')};
  text-align: center;

  ${P} {
    margin: 0;
  }

  a {
    color: ${themeValue('colors.foreground')};
    &:hover {
      color: ${themeValue('colors.foregroundDark')};
    }
  }
`
LayoutFooter.displayName = 'LayoutFooter'

export const LayoutFooterInner = styled(Inset)`
  border-top: 1px solid ${themeValue('colors.secondary.pale')};
  padding-top: ${themeValue('space.base')};
  padding-bottom: ${themeValue('space.medium')};
`
LayoutFooterInner.displayName = 'LayoutFooterInner'

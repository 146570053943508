import React from 'react'
import styled, { css } from 'styled-components'

import { formElementBase } from '../utils'
import { themeValue } from '../theme'
import { Icon } from '../Icon'

const SelectList = styled.select`
  ${formElementBase()};
  background-color: transparent;
  border: none;
  padding-right: ${themeValue('space.medium')};
  z-index: 6;

  &:focus {
    outline: none;
    box-shadow: 0 0 1px ${themeValue('overlayColors.primary')};
    background-color: ${themeValue('colors.backgroundDark')};
  }
`
SelectList.displayName = 'SelectList'

const SelectListIcon = styled(Icon).attrs({
  type: 'chevron',
  rotate: 90,
})`
  color: ${themeValue('colors.foreground')};
  position: absolute;
  right: ${themeValue('space.tiny')};
  top: 50%;
  margin-top: -1.2rem;
  z-index: 5;
  user-select: none;
  ${props =>
    props.hasError &&
    css`
      color: ${themeValue('colors.error.base')} !important;
    `}

  ${props =>
    props.disabled &&
    css`
      display: none;
    `};
`
SelectListIcon.displayName = 'SelectListIcon'

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${themeValue('colors.secondary.highlight')};
  border-radius: ${themeValue('borderRadius')};
  background-color: ${themeValue('colors.background')};
  position: relative;
  cursor: pointer;

  &:hover {
    outline: none;
    box-shadow: 0 0 1px ${themeValue('overlayColors.primary')};
    background-color: ${themeValue('colors.backgroundDark')};
  }

  ${props =>
    props.hasError &&
    css`
      border-color: ${themeValue('colors.error.base')};
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: ${themeValue('colors.backgroundDark')};
    `};
`
SelectContainer.displayName = 'SelectContainer'

export const Select = ({ children, hasError, disabled, ...rest }) => {
  return (
    <SelectContainer disabled={disabled} hasError={hasError}>
      <SelectList disabled={disabled} {...rest}>
        {children}
      </SelectList>
      <SelectListIcon disabled={disabled} size={24} hasError={hasError} />
    </SelectContainer>
  )
}

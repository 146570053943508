import styled from 'styled-components'

import { themeValue } from '../theme'
import { P } from '../Typography'

export const BrandingMotto = styled(P)`
  margin: 0;
  text-transform: uppercase;
  font-weight: ${themeValue('fontWeights.bold')};
  font-size: 1.1rem;
  text-align: center;
`
BrandingMotto.displayName = 'BrandingMotto'

export const BrandingContainer = styled.div`
  a {
    display: block;
    text-decoration: none;
    height: inherit;
  }

  img {
    height: inherit;
    width: auto;
    display: block;
  }
`
BrandingContainer.displayName = 'BrandingContainer'

import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { themeValue } from '../theme'
import { fontSize, space } from '../utils'

const Button = styled.button`
  ${fontSize(0)};
  transition: all 0.15s ease-in-out;
  appearance: none;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  font-family: inherit;
  padding: ${space(0.35)} ${themeValue('space.base')};
  border: 1px solid transparent;
  border-radius: ${themeValue('borderRadius')};
  text-transform: uppercase;
  font-weight: ${themeValue('fontWeights.bold')};

  &:hover,
  &:focus {
    outline: none;
  }

  &:disabled {
    &,
    &:focus,
    &:hover {
      background-color: ${themeValue('colors.secondary.highlight')};
      border-color: ${themeValue('colors.secondary.highlight')};
      color: ${themeValue('colors.secondary.intense')};
      cursor: not-allowed;
    }
  }
`
Button.displayName = 'Button'

export const PrimaryButton = styled(Button)`
  color: ${themeValue('colors.always.white')};
  background-color: ${themeValue('colors.primary.base')};
  border-color: ${themeValue('colors.primary.base')};

  &:hover,
  &:focus {
    border-color: ${themeValue('colors.primary.interaction')};
    background-color: ${themeValue('colors.primary.interaction')};
    color: ${themeValue('colors.always.white')};
  }
`
PrimaryButton.displayName = 'PrimaryButton'

export const SecondaryButton = styled(Button)`
  border-color: ${themeValue('colors.secondary.base')};
  color: ${themeValue('colors.secondary.intense')};

  &:hover,
  &:focus {
    background-color: ${themeValue('colors.secondary.interaction')};
    color: ${themeValue('colors.always.white')};
  }
`
SecondaryButton.displayName = 'SecondaryButton'

export const WarningButton = styled(Button)`
  color: ${themeValue('colors.always.white')};
  background-color: ${themeValue('colors.error.base')};
  border-color: ${themeValue('colors.error.base')};

  &:hover,
  &:focus {
    background-color: ${themeValue('colors.error.interaction')};
    border-color: ${themeValue('colors.error.interaction')};
  }
`
WarningButton.displayName = 'WarningButton'

export const BareButton = styled.button`
  transition: all 0.15s ease-in-out;
  appearance: none;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  font-family: inherit;
  color: inherit;
  background-color: transparent;
  border-color: transparent;
  text-transform: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;

  &:hover,
  &:focus {
    background-color: ${themeValue('colors.backgroundDark')};
  }

  &:disabled {
    cursor: not-allowed;
  }
`
BareButton.displayName = 'BareButton'

export const IconButton = styled(Button)`
  color: ${themeValue('colors.foreground')};
  background-color: transparent;
  border-color: transparent;
  padding: ${space(0.1)};

  &:hover {
    color: ${themeValue('colors.background')};
    background-color: ${themeValue('colors.secondary.base')};
  }

  svg {
    width: 24px;
    height: 24px;
  }
`
IconButton.displayName = 'IconButton'

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  ${props =>
    props.align &&
    css`
      justify-content: ${props.align};
    `}

  & > * {
    &:nth-child(n + 2) {
      margin-left: ${themeValue('space.small')};
    }
  }
`
ButtonGroup.displayName = 'ButtonGroup'
ButtonGroup.propTypes = {
  align: PropTypes.string,
}

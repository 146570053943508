import React, { Component } from 'react'

import MaintenanceNotice from './MaintenanceNotice'
import { NotificationError } from 'ui'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }

  componentDidCatch(error, info) {
    const message = error.message ? error.message : error

    // show the error to the user
    this.setState({ error: message })
  }

  render() {
    const { error } = this.state
    const { message } = this.props

    if (error) {
      if (message) {
        // if a custom error message was provided
        // by prop, prefer that
        return <NotificationError>{message}</NotificationError>
      } else {
        // otherwise use the message that was thrown

        if (error.toLowerCase().indexOf('loading chunk') >= 0) {
          // if the thrown error is due to failed chunks
          return <MaintenanceNotice />
        } else {
          // otherwise simply show the failed message on screen
          return <NotificationError>{error}</NotificationError>
        }
      }
    }

    return this.props.children
  }
}

import React, { Fragment } from 'react'

import { LayoutHeader, LayoutHeaderInner, OnlySmallBreakpoint, MediumBreakpoint } from 'ui'
import { Branding } from './common/Branding'
import { MobileMenu } from './common/MobileMenu'
import { DesktopMenu } from './common/DesktopMenu'
import { ModelSwitcher } from './common/ModelSwitcher'

export const Header = ({ showSwitcher = true }) => (
  <Fragment>
    <LayoutHeader>
      <LayoutHeaderInner>
        <Branding />

        <OnlySmallBreakpoint>
          <MobileMenu />
        </OnlySmallBreakpoint>

        <MediumBreakpoint>
          <DesktopMenu />
        </MediumBreakpoint>
      </LayoutHeaderInner>
    </LayoutHeader>

    {showSwitcher && <ModelSwitcher />}
  </Fragment>
)

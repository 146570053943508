import { css } from 'styled-components'
import get from 'lodash/get'

import { hexToRGB } from './utils'

/**
 * Get a theme value from a path
 * Modified from https://github.com/jxnblk/styled-system
 *
 * @param {string} path -
 */
export const themeValue = (path) => (props) => {
  const value = get(props.theme, path)

  if (value) {
    return value
  } else {
    throw new Error(`The provided path - ${path} - was not found in the theme.`)
  }
}

//
// Main theme colors
//
const colors = {
  primary: {
    pale: '#52ABDD',
    base: '#2B6497', // => "hoopla blue"
    interaction: '#1E466A',
    highlight: '#3882C5',
  },
  secondary: {
    pale: '#E7E7E7',
    base: '#9A9A9A',
    interaction: '#7B7B7B',
    highlight: '#C8C8C8',
    intense: '#5b5b5b',
  },
  accent: {
    base: '#FF7100',
    interaction: '#A64A00',
    highlight: '#FF9540',
    pale: '#fbd4b6',
  },
  error: {
    base: '#D00D00',
    interaction: '#A00A00',
  },
  success: {
    base: '#5BB190',
    interaction: '#509C7F',
  },
  warning: {
    base: '#FF9540',
    interaction: '#FF7100',
  },
  available: '#5BB190',
  unavailable: '#D00D00',
  // => common foreground color, should have sufficient contrast with common background color
  foreground: '#444444',
  // => common foreground color (slightly darker foreground)
  foregroundDark: '#2A2A2A',
  // => common background color
  background: '#FFFFFF',
  // => common background color (slightly darker background)
  backgroundDark: '#f5f5f5',
  always: {
    // sometimes you know that regardless of theme
    // color changes, you always want white or black
    white: '#FFFFFF',
    black: '#444444',
  },
  transparent: 'transparent',
}

//
// These are the weights we have synced from Google fonts. To
// add another weight, change the @import from Google located
// in the GlobalStyle component below.
//
const fontWeights = {
  normal: 400,
  bold: 600,
  bolder: 700,
}

//
// These sizes are used for breakpoints.
// easily use them with the mediaQuery map below.
//
const siteWidth = 1240
const sizes = {
  small: 600,
  medium: 820,
  mediumLarge: 1024,
  large: siteWidth,
  xlarge: 1600,
  huge: 2200,
}

//
// The main site theme
//
const rems = 10 // the pixel equivalent value you want 1rem to be
const baseFontSize = 14 // pixel size equivalent of body text size
const baseLineHeight = 1.35
const baseRhythmUnit = (baseFontSize * baseLineHeight) / rems

export const theme = {
  space: {
    // these values are based on the line height and base font size
    // in order to assure proper vertical rhythm
    tiny: baseRhythmUnit / 4 + 'rem',
    small: baseRhythmUnit / 2 + 'rem',
    base: baseRhythmUnit + 'rem',
    medium: 2 * baseRhythmUnit + 'rem',
    large: 3 * baseRhythmUnit + 'rem',
    xlarge: 5 * baseRhythmUnit + 'rem',
  },
  colors,
  borderRadius: '4px',
  boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.1)',
  overlayColors: {
    primary: hexToRGB(colors.primary.base, 0.9),
    secondary: hexToRGB(colors.secondary.highlight, 0.9),
    accent: hexToRGB(colors.accent.highlight, 0.9),
    background: hexToRGB(colors.background, 0.9),
  },
  sizes,
  fontWeights,
  typography: {
    rems,
    baseFontSize,
    baseLineHeight,
    fontScale: [0.85, 1, 1.25, 1.563, 1.953, 2.441, 3.052],
    sans: '"Source Sans Pro", Arial, sans-serif',
    color: colors.foreground,
  },
  siteWidth,
}

const colorsDark = {
  ...colors,
  primary: {
    pale: '#31adee',
    base: '#2c6da1',
    interaction: '#2d80b2',
    highlight: '#2eb4e9',
  },
  // => common foreground color, should have sufficient contrast with common background color
  foreground: '#FFFFFF',
  // => common foreground color (slightly darker foreground)
  foregroundDark: '#f5f5f5',
  // => common background color
  background: '#282828',
  // => common background color (slightly darker background)
  backgroundDark: '#1f1f1f',
}

export const themeDark = {
  ...theme,
  colors: colorsDark,
}

/**
 * Media Queries
 * taken from: https://www.styled-components.com/docs/advanced#media-templates
 * usage:
 * ${media.small`background: dodgerblue;`}
 * ${media.medium`background: mediumseagreen;`}
 * ${media.large`
 *  background: palevioletred;
 *  display: block;
 * `}
 */
export const mediaQuery = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) =>
    css`
      @media (min-width: ${sizes[label]}px) {
        ${css(...args)};
      }
    `

  return acc
}, {})

import styled, { css } from 'styled-components'

import { themeValue } from '../theme'
import { alignProp, colorProp, fontSize, visuallyHidden } from '../utils'

export const H1 = styled.h1`
  ${fontSize(5)};
  font-weight: ${themeValue('fontWeights.bold')};
  ${props => props.color && colorProp(props.color)};
  ${props => props.align && alignProp(props.align)};
  ${props => fontSize(props.fontSize)};
  ${props =>
    props.transform &&
    css`
      text-transform: ${props.transform};
    `};
  ${props => props.visuallyHidden && visuallyHidden()};
`
H1.displayName = 'H1'

export const H2 = styled.h2`
  ${fontSize(4)};
  font-weight: ${themeValue('fontWeights.bold')};
  ${props => props.color && colorProp(props.color)};
  ${props => props.align && alignProp(props.align)};
  ${props => fontSize(props.fontSize)};
  ${props =>
    props.transform &&
    css`
      text-transform: ${props.transform};
    `};
  ${props => props.visuallyHidden && visuallyHidden()};
`
H2.displayName = 'H2'

export const H3 = styled.h3`
  ${fontSize(3)};
  font-weight: ${themeValue('fontWeights.bold')};
  ${props => props.color && colorProp(props.color)};
  ${props => props.align && alignProp(props.align)};
  ${props => fontSize(props.fontSize)};
  ${props =>
    props.transform &&
    css`
      text-transform: ${props.transform};
    `};
  ${props => props.visuallyHidden && visuallyHidden()};
`
H3.displayName = 'H3'

export const H4 = styled.h4`
  ${fontSize(2)};
  font-weight: ${themeValue('fontWeights.bold')};
  text-transform: uppercase;
  ${props => props.color && colorProp(props.color)};
  ${props => props.align && alignProp(props.align)};
  ${props => fontSize(props.fontSize)};
  ${props =>
    props.transform &&
    css`
      text-transform: ${props.transform};
    `};
  ${props => props.visuallyHidden && visuallyHidden()};
`
H4.displayName = 'H4'

export const P = styled.p`
  ${fontSize(1)};
  margin: 0;

  ${props => props.color && colorProp(props.color)};
  ${props => props.align && alignProp(props.align)};
  ${props => fontSize(props.fontSize)};
  ${props =>
    props.transform &&
    css`
      text-transform: ${props.transform};
    `};
  ${props => props.visuallyHidden && visuallyHidden()};

  & + & {
    margin-top: ${themeValue('space.base')};
  }
`
P.displayName = 'P'

export const Em = styled.em`
  font-size: inherit;
  font-style: italic;

  ${props => props.color && colorProp(props.color)};
  ${props => props.align && alignProp(props.align)};
  ${props => fontSize(props.fontSize)};
  ${props =>
    props.transform &&
    css`
      text-transform: ${props.transform};
    `};
`
Em.displayName = 'Em'

export const Strong = styled.strong`
  font-size: inherit;
  font-weight: ${themeValue('fontWeights.bolder')};
  ${props => props.color && colorProp(props.color)};
  ${props => props.align && alignProp(props.align)};
  ${props => fontSize(props.fontSize)};
  ${props =>
    props.transform &&
    css`
      text-transform: ${props.transform};
    `};
`
Strong.displayName = 'Strong'

export const Span = styled.span`
  font-size: inherit;
  ${props => props.color && colorProp(props.color)};
  ${props => props.align && alignProp(props.align)};
  ${props => fontSize(props.fontSize)};
  ${props =>
    props.transform &&
    css`
      text-transform: ${props.transform};
    `};
  ${props => props.visuallyHidden && visuallyHidden()};
`
Span.displayName = 'Span'

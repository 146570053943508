import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { AppState } from '../../store/GlobalState'
import {
  BareButton,
  DropdownContainer,
  DropdownMenu,
  DropdownMenuItem,
  DropdownToggle,
  Icon,
  Span,
  themeValue,
} from '../../ui'
import { Portal } from './Portal'
import SelectVendorModal from './SelectVendorModal'

export const UserInfo = () => {
  const toggleId = 'toggle-userinfo'
  const menuId = 'menu-userinfo'
  const menu = useRef(null)
  const navigate = useNavigate()

  const {
    settings: { user, vendor, isMasqueradingVendor },
    logout,
    masqueradeVendor,
  } = useContext(AppState)
  const [isActive, activate] = useState(false)

  const onBodyClick = e => {
    if (menu.current && !menu.current.contains(e.target)) {
      activate(false)
    }
  }

  useEffect(() => {
    if (isActive) {
      window.addEventListener('click', onBodyClick)
    }
    return () => window.removeEventListener('click', onBodyClick)
  }, [isActive])

  return (
    <DropdownContainer ref={menu}>
      {user && vendor && (
        <UserInfoContainer
          id={toggleId}
          aria-haspopup="true"
          aria-controls={menuId}
          onClick={() => activate(!isActive)}>
          <Span align="right" fontSize={0}>
            {user.email}
            <br />
            {vendor.name}
          </Span>
          <Icon type="person" size={32} />
        </UserInfoContainer>
      )}

      <DropdownMenu id={menuId} aria-hidden={!isActive} pose={isActive ? 'open' : 'closed'} isActive={isActive}>
        {user.isAdmin && (
          <Portal closeOnEsc>
            <Portal.Toggle>
              {({ openPortal }) => (
                <DropdownMenuItem>
                  <BareButton
                    type="button"
                    onClick={() => {
                      activate(!isActive)
                      openPortal()
                    }}
                    tabIndex={isActive ? 0 : -1}>
                    <Icon type="change" />
                    <FormattedMessage id="userInfo.menu.switchVendor" defaultMessage="Switch vendors" />
                  </BareButton>
                </DropdownMenuItem>
              )}
            </Portal.Toggle>

            <Portal.Content>
              <SelectVendorModal />
            </Portal.Content>
          </Portal>
        )}

        {user.isAdmin && (
          <DropdownMenuItem>
            <BareButton
              type="button"
              onClick={async () => {
                await masqueradeVendor(!isMasqueradingVendor)
                activate(!isActive)
              }}
              tabIndex={isActive ? 0 : -1}>
              <Icon type="person" />
              {isMasqueradingVendor && <FormattedMessage id="userInfo.menu.showAll" defaultMessage="Show admin view" />}
              {!isMasqueradingVendor && (
                <FormattedMessage id="userInfo.menu.vendorView" defaultMessage="Show vendor view" />
              )}
            </BareButton>
          </DropdownMenuItem>
        )}

        <DropdownMenuItem>
          <BareButton
            type="button"
            onClick={() => {
              navigate('/dashboard/help')
              activate(!isActive)
            }}
            tabIndex={isActive ? 0 : -1}>
            <Icon type="help" />
            <FormattedMessage id="userInfo.menu.help" defaultMessage="Help" />
          </BareButton>
        </DropdownMenuItem>

        <DropdownMenuItem>
          <BareButton
            type="button"
            onClick={() => {
              return logout().then(() => navigate('/'))
            }}
            tabIndex={isActive ? 0 : -1}>
            <Icon type="logout" />
            <FormattedMessage id="userInfo.menu.logout" defaultMessage="Logout" />
          </BareButton>
        </DropdownMenuItem>
      </DropdownMenu>
    </DropdownContainer>
  )
}

const UserInfoContainer = styled(DropdownToggle)`
  display: flex;
  align-items: center;
  border: none;

  ${Span} {
    margin-right: ${themeValue('space.small')};
  }
`

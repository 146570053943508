import React, { Suspense, lazy } from 'react'

import { ErrorBoundary } from '../components/common/ErrorBoundary'
import { Helmet } from 'components/common/Helmet'
import { Loading } from 'ui'

export const Users = () => {
  const UserList = lazy(() => import('components/userList/UserList'))

  return (
    <ErrorBoundary>
      <Helmet title="Users" />

      <Suspense fallback={<Loading />}>
        <UserList />
      </Suspense>
    </ErrorBoundary>
  )
}
